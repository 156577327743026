import React from "react";
import cx from "classnames";
import _ from "lodash";
import MainHeading from "../MainHeading";

const MeetTheTeamHeading = (props) => {
  return (
    <>
      <div className={cx(["w-full", "z-[1]"])}>
        <MainHeading
          title={_.get(props.data, ["headline"], "")}
          medium
          gradientOutline
          gradientPurple={process.env.REACT_APP_IS_EOSSITE === "TRUE"}
          customStylesOutline={`"text-[#1c3ba1] ml-[-4vw] sm:ml-[-3.59vw] md:ml-[-3.47vw] lg:ml-[-50px] !whitespace-nowrap ${
            process.env.REACT_APP_IS_EOSSITE === "TRUE" && "opacity-[0.5]"
          }`}
          customStylesTitle={`${
            process.env.REACT_APP_IS_EOSSITE === "TRUE"
              ? "!text-black"
              : "!text-white"
          }  md:!top-[45%]`}
        />
      </div>

      <p
        className={cx([
          "w-full md:w-[60%] max-w-[720px]",
          "mt-[10vw] sm:mt-[6vw] md:mt-[-4.51vw] lg:mt-[-65px]",
          "mb-[10vw] sm:mb-[6vw] md:mb-[2.77vw] lg:mb-[40px]",
          "text-left leading-[normal]",
          "relative z-[2]",
        ])}
      >
        {_.get(props.data, ["strapline"], "")}
      </p>
    </>
  );
};

export default MeetTheTeamHeading;
