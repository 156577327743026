import React, { useRef, useState, useEffect } from "react";
import cx from "classnames";
// import scrollImage from "../assets/images/scroll.png";
import closeIcon from "../assets/images/video-close-button.png";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";

const ScrollSnapSection = (props) => {
  /*scroll snap*/
  const ref = useRef(null);

  var isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
    );

  const smoothScroll = (el) => {
    if (typeof el === undefined) {
      return;
    }

    /*For safari that doesn't work with css scroll-snap and smooth scroll at the same time */
    if (isSafari) {
      document.getElementsByClassName(
        "scroll-snap-container"
      )[0].style.scrollSnapType = "none";
    }

    el.scrollIntoView({ behavior: "smooth" });

    /*For safari that doesn't work with css scroll-snap and smooth scroll at the same time */
    if (isSafari) {
      setTimeout(() => {
        document.getElementsByClassName(
          "scroll-snap-container"
        )[0].style.scrollSnapType = "y mandatory";
      }, 300);
    }
  };

  const onClickScrollDown = React.useCallback(() => {
    smoothScroll(ref.current.nextSibling);
    // eslint-disable-next-line
  }, []);

  // const onClickScrollUp = React.useCallback(() => {
  //   smoothScroll(ref.current.previousSibling);
  // });

  /*video*/
  const vidRef = useRef(null);
  const [playVideo, setPlayVideo] = useState(false);

  const handlePlayVideo = () => {
    if (playVideo) {
      vidRef.current.pause();
      setPlayVideo(false);
    } else {
      vidRef.current.play();
      setPlayVideo(true);
    }
  };

  const closeVideo = () => {
    vidRef.current.pause();
    setPlayVideo(false);
  };

  /*elements*/
  const videoBackground = props.backgroundVideo ? (
    <video
      src={`https://${props.backgroundVideo.replace("https://", "")}`}
      loop
      muted
      playsInline
      autoPlay
      className={cx([
        "absolute",
        "w-[100vw] max-w-[100vw] h-[100vh] max-h-[100vh] object-cover object-center",
      ])}
    />
  ) : (
    ""
  );

  const video = props.video ? (
    <video
      playsInline
      ref={vidRef}
      src={`https://${props.video}`}
      onEnded={() => setPlayVideo(false)}
      className={cx([
        "transition duration-300",
        "absolute",
        "w-[100vw] max-w-[100vw] h-[100vh] max-h-[100vh] object-cover object-center",
      ])}
      style={{
        opacity: playVideo ? "1" : "0",
      }}
      id="video"
    />
  ) : (
    ""
  );

  const overlay = props.showOverlay ? (
    <div
      className={cx([
        playVideo && "hidden",
        "bg-[#00000066] absolute top-0 left-0 bottom-0 right-0",
      ])}
    ></div>
  ) : (
    ""
  );

  // const scrollButton = props.showScrollButton ? (
  //   <button
  //     onClick={props.scrollUp === true ? onClickScrollUp : onClickScrollDown}
  //     className={cx([
  //       "hidden md:block",
  //       "absolute right-[unset] md:right-[4.16vw] lg:right-[60px] bottom-[unset] md:bottom-[2.77vw] lg:bottom-[40px]",
  //       "w-full max-w-[unset] md:max-w-[6.25vw] lg:max-w-[90px]",
  //       "cursor-pointer z-[3]",
  //     ])}
  //   >
  //     <img
  //       src={scrollImage}
  //       alt=""
  //       style={{
  //         transform: props.scrollUp === true ? "rotate(180deg)" : "rotate(0)",
  //       }}
  //     />
  //   </button>
  // ) : (
  //   ""
  // );

  const scrollIcon = props.showScrollIcon ? (
    <button
      onClick={onClickScrollDown}
      className={cx([
        "absolute left-[50%] translate-x-[-50%] bottom-0",
        "w=full mb-[17.94vw] sm:mb-[3.59vw] md:mb-[1.38vw]  lg:mb-[20px]",
        "cursor-pointer z-[3]",
      ])}
    >
      <div
        className={cx(
          "scroll-icon",
          "w-[25px] h-[40px]",
          "rounded-[70px] relative",
          "flex justify-center items-start",
          "before:block before:content-[''] before:w-[5px] before:h-[5px] before:bg-white before:absolute before:top-[10px] before:left-[50%] before:translate-x-[-50%] before:rounded-[50%] before:z-[2]",
          "after:content-[''] after:block after:w-[1px] after:h-[calc(100%-20px)] after:absolute after:top-[50%] after:left-[50%] after:translate-x-[-50%] after:translate-y-[-50%] after:bg-white z-[1]"
        )}
        style={{ boxShadow: "inset 0 0 0 1px #fff" }}
      ></div>
    </button>
  ) : (
    ""
  );

  const videoPlayButton = props.video ? (
    <div
      className={cx([
        "w-full",
        "absolute bottom-[0] left-[0]",
        "flex justify-center items-center",
        "mb-[10.25vw] sm:mb-[5.99vw] md:mb-[3.47vw] lg:mb-[50px]",
      ])}
    >
      <div
        className={cx([
          "btn-standard video-button",
          "transition duration-300",
          "inline-block",
        ])}
        style={{
          opacity: playVideo ? "0" : "1",
          zIndex: playVideo ? "0" : "1",
        }}
      >
        <button
          className={cx(["flex justify-between items-center"])}
          onClick={handlePlayVideo}
        >
          WATCH CORPORATE OVERVIEW
          <span
            className={cx([
              "inline-flex justify-center items-center",
              "relative bottom-[-1px]",
              "ml-[10px] sm:ml-[20px]",
            ])}
          >
            &#9658;
          </span>
        </button>
      </div>
      <div
        className={cx([
          "btn-standard video-button",
          "inline-block",
          "absolute",
          "transition duration-300",
        ])}
        style={{
          opacity: playVideo ? "1" : "0",
          zIndex: playVideo ? "1" : "0",
        }}
      >
        <button
          className={cx(["flex justify-between items-center"])}
          onClick={handlePlayVideo}
        >
          PAUSE FILM
          <span
            className={cx([
              "inline-flex justify-center items-center",
              "relative top-[-1px]",
              "ml-[10px] sm:ml-[20px]",
              "text-[13px]",
            ])}
          >
            &#9616;&nbsp;&#9612;
          </span>
        </button>
      </div>
    </div>
  ) : (
    ""
  );

  const videoCloseButton = props.video ? (
    <div
      className={cx([
        "transition duration-300",
        "inline-block",
        "w-[12.82vw] sm:w-[11.99vw] md:w-[6.94vw] lg:w-[100px]",
        "absolute top-[6.41vw] sm:top-[5.99vw] md:top-[3.47vw] lg:top-[50px] right-[6.41vw] sm:right-[5.99vw] md:right-[3.47vw] lg:right-[50px]",
      ])}
      style={{
        opacity: playVideo ? "1" : "0",
        pointerEvents: playVideo ? "auto" : "none",
        cursor: playVideo ? "pointer" : "none",
      }}
      onClick={closeVideo}
    >
      <img src={closeIcon} alt="" />
    </div>
  ) : (
    ""
  );

  /*service nav menu animation*/
  const history = useHistory();
  const location = useLocation();
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    setShouldAnimate(location.state && location.state.from === "services");
  }, [location]);

  useEffect(() => {
    if (location.state && location.state.from === "services") {
      setTimeout(() => {
        const state = { ...history.location.state };
        delete state.from;
        history.replace({ ...history.location, state });
      }, 500);
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <section
      ref={ref}
      className={cx([
        "scroll-snap-section",
        shouldAnimate ? "animation" : "no-animation",
      ])}
      id={props.id}
      style={{
        backgroundImage: `url(${props.backgroundImage})`,
        backgroundColor: props.backgroundColor,
        zIndex: playVideo && "10001",
      }}
    >
      <div className={cx(["absolute top-0 left-0", "w-full h-full", "z-[0]"])}>
        {videoBackground}
        {video}
        {overlay}
      </div>

      {scrollIcon}

      <div
        className={cx([
          "transition duration-300",
          "scroll-snap-content-wrapper",
          props.isCardStyle ? "card-style" : "",
        ])}
        style={{
          opacity: playVideo ? "0" : "1",
        }}
      >
        {props.children}
      </div>

      {videoPlayButton}
      {videoCloseButton}
    </section>
  );
};

export default ScrollSnapSection;
