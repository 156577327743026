import React from "react";
import cx from "classnames";
import _ from "lodash";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

SwiperCore.use([Pagination, Navigation]);

const Gallery = (props) => {
  let slides = _.get(props, ["gallery"], []).map((g, i) => {
    return (
      <SwiperSlide key={i}>
        <img src={g.image} alt={g.title} className={cx([""])} />
      </SwiperSlide>
    );
  });

  let swiper = "";
  if (props.gallery) {
    swiper = (
      <Swiper
        speed={1000}
        pagination={{ clickable: true }}
        navigation={props.gallery.length > 1}
        grabCursor={props.gallery.length > 1}
        mousewheel={true}
        allowTouchMove={props.gallery.length > 1}
        loop={props.gallery.length > 3}
        centeredSlides={true}
        spaceBetween={0}
        slidesPerView={1}
        initialSlide={1}
        breakpoints={{
          641: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
        }}
      >
        {slides}
      </Swiper>
    );
  }

  return (
    <div
      className={cx([
        `gallery ${
          process.env.REACT_APP_IS_EOSSITE === "TRUE" ? "light" : "dark"
        }`,
        "max-w-[1680px] mr-auto ml-auto",
        `${props.photographer && "mt-[150px]"}`,
      ])}
    >
      {props.photographer && (
        <div class="page-width my-[10px]">
          <h4 className="text-[18px] inline mr-[5px]">Photographer:</h4>
          <span className="text-[15px] opacity-[0.8]">
            {props.photographer}
          </span>
        </div>
      )}

      {swiper}
    </div>
  );
};

export default Gallery;
