import React from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import SmallHeading from "./SmallHeading";
import { useLocation } from "react-router-dom";

const ServiceListItem = (props) => {
  const onMouseEnterHandler = (data) => {
    props.mouseEnterHandler(data);
  };

  const onMouseLeaveHandler = () => {
    if (props.isSingleServicePage) {
      props.mouseEnterHandler("");
    }
  };

  return (
    <li
      className="scroll-snap-horizontal-item"
      onMouseEnter={() => onMouseEnterHandler(props.backgroundImage)}
      onMouseLeave={() => onMouseLeaveHandler("")}
    >
      <NavLink
        to={{
          pathname: "/services/" + props.slug,
          search: `?scroll=${props.scroll}&order=${props.order}`,
          state: { from: useLocation().pathname.replace("/", "") },
        }}
      >
        <div className={cx(["h-full", "flex justify-center flex-wrap"])}>
          <div className={cx(["scroll-snap-item-text-container"])}>
            <div>
              <div
                className={cx([
                  "small-heading",
                  props.collapse === "true" ? "fade-in" : "",
                ])}
              >
                <SmallHeading title={props.title} />
              </div>

              <div className={cx(["description", "relative z-2"])}>
                <p
                  className={cx([
                    "mb-[3.84vw] sm:mb-[4.61vw] md:mb-[3.75vh] lg:mb-[30px] mt-[6px] sm:mt-[10px]",
                    "text-white leading-[1.7] text-[14px] sm:text-[17px] md:text-[0.97vw] lg:text-[14px]",
                  ])}
                >
                  {props.description}
                </p>
              </div>
              <div className={cx(["learn-more"])}>
                <span
                  className={cx([
                    "pb-[7px]",
                    "border-b-[1px] border-[white] border-solid",
                    "text-white leading-[1] text-[14px] sm:text-[14px] md:text-[12px]",
                  ])}
                >
                  LEARN MORE
                </span>
              </div>
            </div>
          </div>

          <div
            className={cx([
              "scroll-snap-item-image-container",
              "w-full",
              "flex justify-end items-end",
              "mt-[auto] ml-[auto]",
              "pt-[5%] pb-[10%] pr-[10%] pl-[40%] md:pl-[30%]",
              "",
            ])}
          >
            <div
              className={cx(["scroll-snap-item-image", "w-full h-full"])}
              style={{
                backgroundImage: `url(${props.icon})`,
              }}
            ></div>
          </div>
        </div>
      </NavLink>
    </li>
  );
};

export default ServiceListItem;
