import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";

const Tag = (props) => {
  return (
    <li className={cx(["mr-[7px] mb-[8px]"])}>
      <Link to={`/work?project_keywords=${props.value.id}`}>
        <div
          className={cx([
            "group",
            "inline-block",
            "w-full h-full",
            "relative",
            "!opacity-100",
          ])}
        >
          <span
            className={cx(
              "content-[''] block",
              "w-[calc(100%+3px)] h-[calc(100%+5px)]",
              "absolute top-[50%] left-[-1.5px] rounded-[100px] translate-y-[-50%]",
              "opacity-0 group-hover:opacity-100",
              "transiion duration-300",
              process.env.REACT_APP_IS_EOSSITE === "TRUE"
                ? "bg-gradient-to-r from-[#c639a5] to-[#1c3ba1]"
                : "bg-gradient-to-r from-[#e85127] to-[#e9d46b]"
            )}
          ></span>
          <span
            className={cx(
              "relative",
              "rounded-[100px]",
              "px-[10px] py-[5px]",
              "text-[14px] leading-[1.2] font-500 uppercase",
              "transiion duration-300",
              "z-[2]",
              process.env.REACT_APP_IS_EOSSITE === "TRUE"
                ? "text-black  border-[#f5f5f5] bg-[#f5f5f5] group-hover:bg-white group-hover:bg-opacity-70"
                : "text-white border-[#fcfcfc0a] bg-[#f5f5f5] bg-opacity-20 group-hover:bg-black group-hover:bg-opacity-70"
            )}
          >
            {props.value.name}
          </span>
        </div>
      </Link>
    </li>
  );
};

export default Tag;
