import React, { useState, useEffect } from "react";

import cx from "classnames";
import { NavLink } from "react-router-dom";
import get from "lodash/get";

const ProjectList = (props) => {
  const [timeOut, setTimeOut] = useState(false);

  // show "couldn't load image message if image failed to be loaded within 5000 ms"
  useEffect(() => {
    setTimeout(() => {
      setTimeOut(true);
    }, 5000);
  }, []);

  return (
    <ul
      className={cx([
        "featured-projects",
        "block sm:flex sm:justify-start sm:items-start flex-wrap",
      ])}
    >
      {props.projects.map((p, i) => {
        return (
          <li
            key={i}
            className={cx([
              "opacity-100 hover:opacity-70",
              "transition duration-300",
              "w-full sm:w-[30.333%]",
              "ml-[0] sm:ml-[3%] mb-[25px] sm:mb-[3%]",
            ])}
          >
            <NavLink to={"/work/" + p.id}>
              <div
                style={{
                  backgroundImage: `url(${get(
                    p,
                    ["hero_image", "thumbnail", "http_root"],
                    ""
                  )}${get(
                    p,
                    ["hero_image", "thumbnail", "http_relative_path"],
                    ""
                  )}`,
                }}
                className={cx([
                  "thumbnail",
                  "relative",
                  "bg-[#cccccc] bg-cover bg-center",
                  "flex justify-center items-center",
                ])}
              >
                {!p.hero_image && <div className="shimmer"></div>}
                {p.hero_image_id === 0 || (timeOut && !p.hero_image) ? (
                  <div className="load-error-msg"></div>
                ) : (
                  ""
                )}
              </div>
              <h3 className={cx(["text=[16px]"])}>{p.name}</h3>
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

export default ProjectList;
