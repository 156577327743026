import React from "react";
import cx from "classnames";
import FooterHeading from "./FooterHeading";

const ContactListItem = (props) => {
  return (
    <li
      className={cx([
        "contact",
        `w-full ${props.index === 0 ? "md:w-[60%]" : "md:w-[40%]"}`,
        "mb-[13vw] sm:mb-[5vw] md:mb-[6.59vw] lg:mb-[95px]",
      ])}
    >
      {props.contactOfEosFromALDSite ? (
        <>
          <span
            className={cx([
              "contact-email",
              "block mb-[5px]",
              "fluid-text-36px",
              "!opacity-100",
              "text-black",
              "leading-[normal]",
            ])}
          >
            ALD is an Eos Lightmedia Studio
          </span>
          <span className={cx("text-[14px] uppercase text-black")}>
            For full service offering visit&nbsp;
            <a
              className={cx(
                "gradient-text-type1",
                "font-bold underline",
                "relative"
              )}
              href="https://www.eoslightmedia.com/"
              target="_blank"
              rel="noreferrer"
            >
              eoslightmedia.com
              <span
                className={cx([
                  "content-[''] block",
                  "w-full h-[1px]",
                  "absolute bottom-[-1px] right-0",
                ])}
                style={{
                  background:
                    "linear-gradient(80deg, #1c3ba1 -1%, #5761b8 45%, #c639a5 98%)",
                }}
              ></span>
            </a>
          </span>
        </>
      ) : (
        <>
          <FooterHeading title={props.title} />
          <div className={cx(["relative", "inline-block"])}>
            <a
              className={cx([
                `gradient-text-${props.gradient}`,
                "contact-email",
                "inline-block",
                "font-bold",
                "fluid-text-36px",
              ])}
              href={`mailto:${props.email}`}
            >
              {props.email}
              <span
                className={cx([
                  `gradient-bg-${props.gradient}`,
                  "h-[2px] block",
                  "relative top-[-2vw] sm:top-[-1.19vw] md:top-[-0.69vw] lg:top[-10px]",
                ])}
              ></span>
            </a>
          </div>
        </>
      )}
    </li>
  );
};

export default ContactListItem;
