import React, { useState } from "react";
import cx from "classnames";
import NavigationList from "./NavigationList";
const HamburgerMenu = () => {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div
        onClick={toggleMenu}
        className={cx([
          "w-[20px] h-[20px]",
          "flex justify-center items-center",
          "cursor-pointer",
          "z-[3]",
          open ? "open" : "close",
        ])}
      >
        <div className="nav-button">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div
        className={cx(
          "bg-black bg-opacity-40 backdrop-blur-[5px]",
          "content-[''] w-full h-[calc(100vh-60px)]",
          "fixed top-[60px] left-0 bottom-0 right-0",
          "z-[1]",
          open ? "!block" : "!hidden"
        )}
      ></div>

      <div
        className={cx([
          "hamburger-menu-list",
          "flex justify-center items-center",
          "fixed top-[60px] right-[0]",
          "z-[2]",
          open ? "active" : "",
        ])}
        onClick={closeMenu}
      >
        <div
          className={cx(["bg-white", "w-[80vw] h-full", "ml-auto", "p-[5%]"])}
        >
          <NavigationList isHamburgerMenu closeMenu={closeMenu} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default HamburgerMenu;
