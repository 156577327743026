import React, { useState, useEffect } from "react";
import ScrollSnapSection from "../../components/ScrollSnapSection";
import EmployeesContainer from "../../components/Team/EmployeesContainer";
import { fetchFieldByPage } from "../../http";
import _ from "lodash";

const TeamContainer = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    fetchFieldByPage("team").then((res) => {
      setData(res);
    });
  }, []);

  return (
    <ScrollSnapSection showScrollButton={false} id="team">
      <EmployeesContainer data={_.get(data, ["meet_the_team"], {})} />
    </ScrollSnapSection>
  );
};

export default TeamContainer;
