import React from "react";
import cx from "classnames";
import OfficeListItem from "./OfficeListItem";

const OfficesList = () => {
  const data =
    process.env.REACT_APP_IS_EOSSITE === "TRUE"
      ? [
          {
            address1: "Vancouver (Head Office)",
            address2: "310 - 825 Powell Street Vancouver BC, V6A 1H7 Canada",
            phone: "+1 (604) 639 5488",
          },
          {
            address1: "New York City",
            address2: "",
            phone: "+1 (212) 518 6488",
          },
          {
            address1: "Orlando",
            address2:
              "7914 Kingspointe Parkway UNIT 118 Orlando, FL 32819 United States",
            phone: "+1 (407) 546 5488",
          },
          {
            address1: "Abernathy Lighting Design",
            address2: "",
            phone: "+1 (401) 233-4412",
          },
        ]
      : [
          {
            address1: "Abernathy Lighting Design",
            address2: "",
            phone: "+1 (401) 233-4412",
          },
        ];

  return (
    <ul className={cx(["sm:grid grid-cols-2"])}>
      {data.map(function (data, i) {
        return (
          <OfficeListItem
            key={i}
            address1={data.address1}
            address2={data.address2}
            phone={data.phone}
          />
        );
      })}
    </ul>
  );
};

export default OfficesList;
