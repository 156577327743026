import React from "react";
import cx from "classnames";

const Hero = (props) => {
  return (
    <div
      className={cx([
        "hero",
        "relative flex justify-center items-center flex-wrap",
        "mt-auto mb-[37.17vw] sm:mb-[16.18vw] md:mb-[9.37vw] lg:mb-[135px]",
        "text-left",
      ])}
    >
      <div
        className={cx([
          "text-outline-effect",
          "absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]",
          "mt-[-3.2vw] sm:mt-[-2.5vw] md:mt-[-1.73vw] lg:mt-[-25px] whitespace-pre-wrap",
          "flex justify-center items-center",
          "w-full sm:w-[120vw] md:w-full",
          "leading-[1] text-center whitespace-normal",
          "fluid-text-133px",
          process.env.REACT_APP_IS_EOSSITE === "TRUE"
            ? "sm:whitespace-nowrap"
            : "sm:whitespace-pre-wrap",
        ])}
        style={{
          display: props.showOutlinedHeading === false ? "none" : "block",
        }}
      >
        {props.heading}
      </div>
      <h2
        className={cx([
          "relative",
          "w-full max-w-[880px]",
          "mr-auto ml-auto pl-[10%] md:pl-[unset] pr-[10%] md:pr-[unset]",
          "leading-[normal] text-center text-white",
          "fluid-text-66px",
          process.env.REACT_APP_IS_EOSSITE === "TRUE"
            ? "sm:whitespace-nowrap"
            : "sm:whitespace-pre-wrap",
        ])}
      >
        {props.heading}
      </h2>
    </div>
  );
};

export default Hero;
