import React, { useRef, useState, useEffect } from "react";
import cx from "classnames";

const MainHeading = (props) => {
  const [isMobile, setIsMobile] = useState();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const [inView, setInView] = useState(false);
  const [y, setY] = useState(0);
  const [parentSectionIndex, setParentSectionIndex] = useState(0);
  const sections = document.querySelectorAll(".scroll-snap-section");

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth, setHeight]);

  useEffect(() => {
    setIsMobile(width <= 1024);
  }, [width]);

  useEffect(() => {
    for (let i = 0; i < sections.length; i++) {
      if (sections[i] === ref.current.closest("section")) {
        setParentSectionIndex(i);
        break;
      }
    }
  }, [sections]);

  useEffect(() => {
    if (!isMobile) {
      document
        .querySelector(".scroll-snap-container")
        .addEventListener("scroll", scrollHandlerDesktop);
      return () =>
        document
          .querySelector(".scroll-snap-container")
          .removeEventListener("scroll", scrollHandlerDesktop);
    } else {
      window.addEventListener("scroll", scrollHandlerMobile);
      return () => window.removeEventListener("scroll", scrollHandlerMobile);
    }
  });

  const scrollHandlerDesktop = () => {
    const value = Math.floor(
      ((document.querySelector(".scroll-snap-container").scrollTop -
        height * parentSectionIndex) /
        height) *
        100
    );

    setY(value);
  };

  const scrollHandlerMobile = () => {
    if (ref.current) {
      const value =
        window.pageYOffset -
        (ref.current.getBoundingClientRect().top + window.scrollY);

      setY(value / 10);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [ref]);

  return (
    <div
      className={cx(["relative flex justify-start items-start", "text-left"])}
    >
      <div
        ref={ref}
        style={{
          transform: inView
            ? isMobile
              ? "translateY(calc(" + y * -1 + "px - 3vh))"
              : "translateY(" + y * -1 + "px)"
            : "1",
        }}
        className={cx([
          "absolute md:relative top-0 left-0 whitespace-pre-wrap",
          "translate-y-[30px] md:translate-y-[100px]",
          "transition duration-[1.2s] ease-out",
          props.gradientOutline
            ? `text-outline-effect-gradient ${
                props.gradientPurple ? "purple" : "orange"
              }`
            : "text-outline-effect",
          props.large &&
            "large-heading-outline-title fluid-text-185px leading-[0.94] mt-[-9vw] sm:mt-[-8.99vw] md:mt-[-5.20vw] lg:mt-[-75px]  ml-[-4vw] sm:ml-[-3.59vw] md:ml-[-2.08vw] lg:ml-[-30px] pr-[unset] md:pr-[10%]",
          props.medium &&
            "fluid-text-142px leading-[1.5] text-white top-[-100%] md:top-0",
          props.customStylesOutline && props.customStylesOutline,
        ])}
      >
        {props.title}
      </div>
      <h2
        className={cx([
          "w-full relative md:absolute md:top-0 md:left-0 whitespace-pre-wrap",
          props.large && props.title.length > 10 && "md:w-[50%]",
          props.large &&
            "large-heading-title fluid-text-120px leading-[1] md:leading-[0.9] lg:tracking-[-1px] text-white mb-[40px] sm:mb-[9.23vw] md:mb-0",
          props.medium &&
            "fluid-text-50px leading-[1] text-white md:top-[50%] md:translate-y-[-50%]",
          props.customStylesTitle && props.customStylesTitle,
        ])}
      >
        {props.title}
      </h2>
    </div>
  );
};

export default MainHeading;
