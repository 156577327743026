import React, { useState, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router";
import cx from "classnames";
import {
  fetchProjects,
  fetchProjectsKeywords,
  fetchProjectsFiles,
} from "../../http";
import { useQuery } from "../../Utils/useQuery";
import ProjectList from "./ProjectList";
import { Filter } from "./Filter";
import MainHeading from "../MainHeading";
import ascImg from "../../assets/images/asc.svg";
import ascWhImg from "../../assets/images/asc-wh.svg";
import useWindowDimensions from "../../Utils/useWindowDimensions";

const ProjectsContainer = () => {
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState();
  const query = useQuery();
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [projectsKeywords, setProjectsKeywords] = useState({});
  const [heroImages, setHeroImages] = useState([]);
  const [hideGradientBgOnRight, setHideGradientBgOnRight] = useState(false);
  const [hideGradientBgOnLeft, setHideGradientBgOnLeft] = useState(false);

  useEffect(() => {
    setIsMobile(width <= 1024);
  }, [width]);

  // Fetch projects
  useEffect(() => {
    const payload = {
      keywords: projectKeywordsQuery ? projectKeywordsQuery : [],
      orderBy: {
        sortBy: sortByQuery,
        sort: sortQuery,
      },
    };
    fetchProjects(payload)
      .then((res) => {
        setProjects(res);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [query]);

  // Fetch files
  useEffect(() => {
    if (projects.length > 0) {
      const fileIds = projects.map((p) => p.hero_image_id);

      fetchProjectsFiles(fileIds)
        .then((res) => {
          setHeroImages(res);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }, [projects]);

  // Store query string page
  const pageQuery = useMemo(() => parseInt(query.get("page")) || 100, [query]);

  // Store query string project keywords
  const projectKeywordsQuery = useMemo(
    () => query.getAll("project_keywords").map((q) => parseInt(q)),
    [query]
  );

  // Store query string sortBy
  const sortByQuery = useMemo(
    () => query.get("sortBy") || "completion_date",
    [query]
  );
  // Store query string sort
  const sortQuery = useMemo(() => query.get("sort") || "Desc", [query]);

  // Zip projects and files
  const projectsWithHeroImages = useMemo(() => {
    return projects.map((p) => ({
      ...p,
      hero_image: heroImages.find((h) => h.id === p.hero_image_id),
    }));
  }, [projects, heroImages]);

  // Filter projectsWithHeroImages
  const filteredProjects = useMemo(() => {
    return projectsWithHeroImages.filter((p) => {
      // console.log(projectKeywordsQuery.includes(279));

      // const newProjectKeywordsQuery = projectKeywordsQuery.filter(
      //   (k) => k !== 279
      // );
      // console.log(newProjectKeywordsQuery);

      return projectKeywordsQuery.length > 0
        ? projectKeywordsQuery.every((k) => {
            return p.project_keywords.includes(k);
          })
        : true;
    });
  }, [projectsWithHeroImages, projectKeywordsQuery]);

  // Paginate filteredProjects
  const paginatedProjects = useMemo(() => {
    return filteredProjects.slice(0, pageQuery * 12);
  }, [filteredProjects, pageQuery]);

  // Compute service category filters
  const filters = useMemo(() => {
    return Object.entries(projectsKeywords).map(([key, values]) => {
      return {
        key,
        values: values.keywords
          .map((k) => ({
            id: k.id,
            name: k.name,
            count: filteredProjects.filter((p) =>
              p.project_keywords.includes(k.id)
            ).length,
          }))
          .sort((a, b) => {
            if (a.count > b.count) return -1;
            if (a.count < b.count) return 1;
            return 0;
          }),
      };
    });
  }, [projectsKeywords, filteredProjects]);

  /*
   * get projects keywords
   */
  useEffect(() => {
    fetchProjectsKeywords()
      .then((res) => {
        setProjectsKeywords(res);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  /*
   * fade the containers at the bottom when it's scrollable
   */
  const rightColumnRef = useRef();
  const onScrollRightColumn = () => {
    if (rightColumnRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = rightColumnRef.current;
      setHideGradientBgOnRight(scrollTop + clientHeight >= scrollHeight - 10);
    }
  };

  useEffect(() => {
    if (rightColumnRef.current && rightColumnRef.current.children[0]) {
      const { scrollTop, scrollHeight, clientHeight } = rightColumnRef.current;
      setHideGradientBgOnRight(
        clientHeight > rightColumnRef.current.children[0].clientHeight ||
          scrollTop + clientHeight >= scrollHeight - 10
      );
    }
  }, [query, projects, width]);

  const leftColumnRef = useRef();
  const onScrollLeftColumn = () => {
    if (leftColumnRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = leftColumnRef.current;

      setHideGradientBgOnLeft(scrollTop + clientHeight >= scrollHeight - 10);
    }
  };

  useEffect(() => {
    if (leftColumnRef.current && leftColumnRef.current.children[0]) {
      const { scrollTop, scrollHeight, clientHeight } = leftColumnRef.current;
      setHideGradientBgOnLeft(
        clientHeight > leftColumnRef.current.children[0].clientHeight ||
          scrollTop + clientHeight >= scrollHeight - 10
      );
    }
  }, [query, filters, width]);

  const content = <ProjectList projects={paginatedProjects} />;
  const sortByArray = [
    {
      query: "completion_date",
      label: "Date Completed",
    },
    {
      query: "name",
      label: "Alphabetical",
    },
  ];

  const sortBox = (
    <div
      className={cx(
        "w-auto inline-flex items-start sm:items-center justify-end",
        "flex-wrap sm:flex-nowrap"
      )}
    >
      <div className={cx("w-full grid grid-cols-2 gap-[20px] sm:gap-[24px]")}>
        {sortByArray.map((o) => {
          return (
            <label
              key={o.label}
              className={cx([
                "flex justify-start items-center",
                "cursor-pointer",
                "mb-[7px] sm:mb-0",
              ])}
            >
              <input
                className={cx(["input"])}
                type="checkbox"
                checked={sortByQuery === o.query}
                onChange={() => {
                  query.set("sortBy", o.query);

                  history.replace({
                    search: `?${query.toString()}`,
                  });
                }}
              />

              <span
                className={cx(
                  `checkbox ${
                    process.env.REACT_APP_IS_EOSSITE === "TRUE"
                      ? "purple"
                      : "orange"
                  }`,
                  "inline-block content=[''] min-w-[20px] min-h-[20px] w-[20px] h-[20px]",
                  "border-solid rounded-[50%]",
                  process.env.REACT_APP_IS_EOSSITE === "TRUE"
                    ? "bg-white  border-black border-[2px]"
                    : "bg-black  border-white border-[1px]",
                  "mr-[8px] relative transition duration-300"
                )}
              ></span>
              <span
                className={cx([
                  "text-[15px] lg:text-[16px] leading-[1] font-500",
                  "uppercase",
                ])}
              >
                {o.label}
              </span>
            </label>
          );
        })}
      </div>

      <button
        className={cx(
          "w-[24px] h-[24px]",
          "mr-auto sm:mr-[unset]",
          "bg-no-repeat bg-contain bg-center",
          sortQuery === "Desc" && "rotate-180 scale-x-[-1]",
          "!transition-none"
        )}
        style={{
          backgroundImage: `url(${
            process.env.REACT_APP_IS_EOSSITE === "TRUE" ? ascImg : ascWhImg
          })`,
        }}
        onClick={() => {
          query.set("sort", sortQuery === "Desc" ? "Asc" : "Desc");
          history.replace({
            search: `?${query.toString()}`,
          });
        }}
      ></button>
    </div>
  );

  return (
    <div className={cx(["page-width", "py-[30%] sm:py-[20%] md:py-[5%]"])}>
      <div
        className={cx([
          "w-full",
          "md:mb-[-25px]",
          "flex justify-between items-center",
          "z-[1]",
        ])}
      >
        <MainHeading
          title="Projects"
          medium
          gradientOutline
          gradientPurple={process.env.REACT_APP_IS_EOSSITE === "TRUE"}
          customStylesOutline="text-[#1c3ba1] ml-[-4vw] sm:ml-[-3.59vw] md:ml-[-4.51vw] lg:ml-[-65px]"
          customStylesTitle={`${
            process.env.REACT_APP_IS_EOSSITE === "TRUE"
              ? "!text-black"
              : "!text-white"
          }`}
        />

        <div className="hidden md:block">{sortBox}</div>
      </div>

      <div
        className={cx([
          "w-full",
          "relative",
          "block md:flex justify-between items-start",
          "z-[2]",
        ])}
      >
        <div
          className={cx([
            "left-column scroll-bar",
            "w-full md:w-[25%]",
            "pr-[0] md:pr-[2.5%]",
            "mr-[0] md:mr-[2.5%]",
            "mb-[50px] md:mb-[0]",
            "mt-[30px] md:mt-0",
            "block sm:flex md:block flex-wrap justify-start items-start",
            "z-[2]",
          ])}
          onScroll={() => onScrollLeftColumn()}
          ref={leftColumnRef}
          style={{
            WebkitMaskImage: !isMobile
              ? hideGradientBgOnLeft
                ? "unset"
                : "linear-gradient(to bottom, black calc(100% - 75px), transparent 100%)"
              : "unset",
            maskImage: !isMobile
              ? hideGradientBgOnLeft
                ? "unset"
                : "linear-gradient(to bottom, black calc(100% - 75px), transparent 100%)"
              : "unset",
          }}
        >
          <div
            className={cx(
              "w-full",
              "sm:flex justify-start items-start md:block flex-wrap"
            )}
          >
            {filters.map((f) => (
              <Filter
                key={f.key}
                label={f.key}
                facet={f.key}
                values={f.values}
                count={f.count}
              />
            ))}

            <div
              className={cx("w-full", "block sm:flex md:hidden justify-end")}
            >
              {sortBox}
            </div>
          </div>
        </div>

        <div className={cx(["relative", "w-full md:w-[75%]"])}>
          <div
            className={cx(
              "w-full",
              "absolute left-[50%] bottom-[2.5%] md:bottom-[5%] translate-x-[-50%]",
              hideGradientBgOnRight ? "opacity-0" : "opacity-100",
              "transition duration-300 ease-out"
            )}
          >
            <span
              className={cx(
                "content-[''] block w-[14px] h-[23px]",
                "m-auto relative",
                "border-[1px] border-solid rounded-[40px] shadow-[0 1.6px 1.6px 0 rgba(0, 0, 0, 0.25)]",
                "before:content-[''] before:block before:w-[3px] before:h-[3px] before:rounded-[50%] before:absolute before:left-[50%] before:translate-x-[-50%] before:top-[5px]",
                "after:content-[''] after:w-[0.4px] after:h-[11px] after:absolute after:top-[50%] after:left-[50%] after:transalte-x-[-50%] after:translate-y-[-50%]",
                process.env.REACT_APP_IS_EOSSITE === "TRUE"
                  ? "border-black before:bg-black after:bg-black"
                  : "border-white before:bg-white after:bg-white"
              )}
            ></span>
            <span
              className={cx(
                "block mt-[5px] md:mt-[10px]",
                "text-[14px] md:text-[16px] text-center",
                process.env.REACT_APP_IS_EOSSITE === "TRUE"
                  ? "text-black"
                  : "text-white"
              )}
            >
              Scroll for more projects
            </span>
          </div>

          <div
            className={cx([
              "right-column scroll-bar",
              "pr-[2.5%]",
              "w-full",
              "z-[1]",
              "transition duration-300 ease-out",
            ])}
            onScroll={() => onScrollRightColumn()}
            ref={rightColumnRef}
            style={{
              WebkitMaskImage: hideGradientBgOnRight
                ? "unset"
                : "linear-gradient(to top, transparent 20%, black 35%)",
              maskImage: hideGradientBgOnRight
                ? "unset"
                : "linear-gradient(to top, transparent 20%, black 35%)",
            }}
          >
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsContainer;
