import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Route,
  Switch,
  withRouter,
  Redirect,
  useLocation,
} from "react-router-dom";
import useWindowDimensions from "./Utils/useWindowDimensions";
import { Helmet } from "react-helmet";

import "./styles/output.css";
import "./styles/styles.scss";
import cx from "classnames";

import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import Services from "./pages/Services/Services";
import Service from "./components/Service/Service";
import Projects from "./pages/Projects/Projects";
import Company from "./pages/Company/Company";
import Team from "./pages/Team/Team";
import Contact from "./pages/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Project from "./components/Project/Project";

import { fetchServices } from "./http";
import Privacy from "./pages/Privacy/Privacy";
import checkPath from "./Utils/checkPath";

const App = () => {
  const location = useLocation();
  const { height } = useWindowDimensions();
  const [services, setServices] = useState([]);
  const [scrolled, setScrolled] = useState(false);
  const [isDarkStyleOnHeaderForALD, setIsDarkStyleOnHeaderForALD] =
    useState(false);

  const [numOfSections, setNumOfSections] = useState();

  /*
   * get # of sections
   */
  useEffect(() => {
    setNumOfSections(document.querySelectorAll(".scroll-snap-section").length);
  }, [document.querySelectorAll(".scroll-snap-section")]);

  /*
   * get services data
   */
  useEffect(() => {
    fetchServices().then((res) => {
      setServices(res);
    });
  }, []);

  const [isScrollSnapDisabled, setIsScrollSnapDisabled] = useState(false);
  const [isStickyStyleDisabled, setIsStickyStyleDisabled] = useState(false);

  useEffect(() => {
    setIsScrollSnapDisabled(
      checkPath(location.pathname, "/work/:id") ||
        location.pathname.includes("/services/") ||
        location.pathname === "/team" ||
        location.pathname === "/privacy"
    );
  }, [location]);

  useEffect(() => {
    setIsStickyStyleDisabled(numOfSections < 3);
  }, [numOfSections]);

  let className = "";
  if (location.pathname.replace("/", "") === "") {
    className = "home-page";
  } else if (checkPath(location.pathname, "/services/:slug")) {
    className = "service-single";
  } else if (checkPath(location.pathname, "/work/:id")) {
    className = "work-single";
  } else {
    className = location.pathname.replaceAll("/", "") + "-page";
  }

  /*
   * get scroll top to change navigation menu styles on scroll
   */
  const ref = useRef(null);

  //listen to scroll on a scroll-snap-container for the page with scroll snap effect
  const scrollHandler1 = () => {
    setScrolled(ref.current.scrollTop > height * 0.5);
    if (process.env.REACT_APP_IS_EOSSITE !== "TRUE") scrollHandlerForALD1();
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler2);
    return () => {
      window.removeEventListener("scroll", scrollHandler2);
    };
  });

  //listen to scroll on window for the page without scroll snap effect
  const scrollHandler2 = () => {
    setScrolled(window.scrollY > height * 0.5);
    if (process.env.REACT_APP_IS_EOSSITE !== "TRUE") scrollHandlerForALD2();
  };

  //Set dark style on header when scrolled to the bottom of the page FOR ALD site
  const scrollHandlerForALD1 = () => {
    if (ref.current.scrollTop === 0) {
      setIsDarkStyleOnHeaderForALD(false);
    } else {
      setIsDarkStyleOnHeaderForALD(
        ref.current.scrollTop > (numOfSections - 1) * height - height * 0.5
      );
    }
  };

  const scrollHandlerForALD2 = () => {
    if (window.scrollY === 0) {
      setIsDarkStyleOnHeaderForALD(false);
    } else {
      setIsDarkStyleOnHeaderForALD(
        window.scrollY > document.body.scrollHeight - (height + height * 0.1)
      );
    }
  };

  /*
   * mute the corporate overview video on the company page on scroll
   */
  useEffect(() => {
    if (location.pathname === "/company" && document.getElementById("video")) {
      document.getElementById("video").muted = scrolled;
    }
  }, [scrolled, location.pathname]);

  let content = (
    <div
      onScroll={scrollHandler1}
      ref={ref}
      className={cx([
        "scroll-snap-container",
        isScrollSnapDisabled ? "disable-snap" : "enable-snap",
        className,
      ])}
    >
      <Header
        scrolled={scrolled}
        isStickyStyleDisabled={isStickyStyleDisabled}
        isDarkStyleOnHeaderForALD={isDarkStyleOnHeaderForALD}
      />

      <Switch>
        {process.env.REACT_APP_IS_EOSSITE === "TRUE" && (
          <Route path="/services">
            <Services />
            <Route path="/services/:slug" exact>
              <Service />
            </Route>
          </Route>
        )}

        <Route path="/work/:id" exact>
          <Project />
        </Route>
        <Route path="/privacy" component={Privacy} exact />
        <Route path="/work" component={Projects} exact />
        <Route path="/company" component={Company} exact />
        {process.env.REACT_APP_IS_EOSSITE === "TRUE" && (
          <Route path="/team" component={Team} exact />
        )}
        <Route path="/contact" component={Contact} exact />
        <Route path="/" exact component={Home} />
        <Redirect to="/" />
      </Switch>

      <Footer services={services} />
    </div>
  );

  return (
    <div
      id="App"
      className={cx(
        process.env.REACT_APP_IS_EOSSITE === "TRUE"
          ? "eos-website"
          : "ald-website",
        process.env.REACT_APP_IS_EOSSITE === "TRUE"
          ? "text-black bg-white"
          : "text-white bg-black"
      )}
    >
      <Helmet>
        <title>
          {process.env.REACT_APP_IS_EOSSITE === "TRUE"
            ? "Eos Lightmedia - Using Art and Technology to Transform the Built"
            : "Abernathy Lighting Design"}
        </title>
      </Helmet>

      {content}
    </div>
  );
};

export default withRouter(App);
