import React from "react";
import cx from "classnames";
import placeholderImage from "../../assets/images/avatar_placeholder.jpg";

const EmployeesListItem = (props) => {
  const color1 =
    props.colors[props.employee.primary_department] &&
    props.colors[props.employee.primary_department].dark;
  const color2 =
    props.colors[props.employee.primary_department] &&
    props.colors[props.employee.primary_department].light;
  const handleClick = (id) => {
    props.showEmployeeBioHandler(id);
  };

  return (
    <li className="mb-[30px]">
      <img
        src={props.employee.avatar ? props.employee.avatar : placeholderImage}
        className={cx([
          "w-[100px] h-[100px] sm:w-[120px] sm:h-[120px] md:w-[160px] md:h-[160px] object-cover object-center",
          "block ml-[auto] mr-[auto]",
          "rounded-[50%]",
          `border-[2px] border-${color2} border-solid`,
          process.env.REACT_APP_IS_EOSSITE !== "TRUE" && "border-orange",
          "mb-[15px]",
          "cursor-pointer",
        ])}
        onClick={() => {
          handleClick(props.employee.id);
        }}
        alt={props.employee.name}
      />
      <h4
        className={cx([
          `text-${color1} text-center text-[14px] font-bold leading-[1.15]`,
          process.env.REACT_APP_IS_EOSSITE !== "TRUE" && "!text-orange",
          "mb-[3px]",
        ])}
      >
        {props.employee.name}
      </h4>
      <div
        className={cx([
          "text-center text-[12px] leading-[1.5] sm:max-w-[60%] mx-auto",
          process.env.REACT_APP_IS_EOSSITE === "TRUE"
            ? "text-black"
            : "text-white",
        ])}
      >
        {props.employee.title}
      </div>
    </li>
  );
};

export default EmployeesListItem;
