import React, { useState, useEffect, useMemo, useRef } from "react";
import cx from "classnames";
import http from "../../http";
import _ from "lodash";

import EmployeesList from "./EmployeesList";
import Loader from "../Loader";

import placeholderImage from "../../assets/images/avatar_placeholder.jpg";
import nextButton from "../../assets/images/next-button.svg";
import prevButton from "../../assets/images/prev-button.svg";
import linkedinIcon from "../../assets/images/linkedin-icon.svg";
import emailIcon from "../../assets/images/email-icon.svg";

import MeetTheTeamHeading from "./MeetTheTeamHeading";
import useWindowDimensions from "../../Utils/useWindowDimensions";

const EmployeesContainer = (props) => {
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [, setError] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState(["All"]);
  const [sortBy, setSortBy] = useState("first_initial");
  const [department, setDepartment] = useState("All");
  const [colors, setColors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedEmployeeId, setEmployeeId] = useState();
  const [hideGradientBgOnList, setHideGradientBgOnList] = useState(false);

  /*
   * fade the containers at the bottom when it's scrollable
   */
  const listRef = useRef();
  const onScrollList = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      setHideGradientBgOnList(scrollTop + clientHeight >= scrollHeight - 10);
    }
  };

  useEffect(() => {
    if (listRef.current && listRef.current.children[0]) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      setHideGradientBgOnList(
        clientHeight > listRef.current.children[0].clientHeight ||
          scrollTop + clientHeight >= scrollHeight - 10
      );
    }
  }, [width]);

  const filtered = useMemo(() => {
    return (
      employees
        .map((e) => ({ ...e, first_initial: e.name.charAt(0) }))
        // filter by department
        .filter((e) => {
          return (
            department === "All" ||
            department === e.primary_department ||
            e.secondary_departments.includes(department)
          );
        })
        // sort by employee key (time_zone or primary_department)
        .sort((a, b) => {
          if (sortBy && a[sortBy] < b[sortBy]) {
            return -1;
          }
          if (sortBy && a[sortBy] > b[sortBy]) {
            return 1;
          }
          return 0;
        })
    );
  }, [employees, sortBy, department]);

  // group by employee key (time_zone or primary_department)
  const grouped = useMemo(() => {
    return filtered.reduce((a, f) => {
      (a[f[sortBy]] = a[f[sortBy]] || []).push(f);
      return a;
    }, {});
  }, [filtered, sortBy]);

  const selectedEmployeeIdIndex = useMemo(() => {
    return filtered.findIndex((e) => e.id === selectedEmployeeId);
  }, [filtered, selectedEmployeeId]);

  const selectedEmployee = useMemo(() => {
    return filtered.find((e) => e.id === selectedEmployeeId);
  }, [filtered, selectedEmployeeId]);

  const handlePrev = () => {
    setEmployeeId(filtered[selectedEmployeeIdIndex - 1].id);
  };

  const handleNext = () => {
    setEmployeeId(filtered[selectedEmployeeIdIndex + 1].id);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      http
        .get("/employees")
        .then((res) => {
          const employeesData = _.get(res, ["data"], []).map((e) => {
            return {
              ...e,
              avatar: (
                _.get(e, ["avatar", "sizes", 0, "http_root"], "") +
                _.get(e, ["avatar", "sizes", 0, "http_relative_path"], "")
              ).replace(/\/\//, "https://"),
            };
          });
          setEmployees(employeesData);

          const departmentsData = res.data.reduce((a, e) => {
            if (
              !a.includes(e.primary_department) &&
              e.primary_department !== ""
            ) {
              a.push(e.primary_department);
            }

            if (e.secondary_departments.length !== 0) {
              const d = e.secondary_departments.find((d) => a.indexOf(d) < 0);
              d && a.push(d);
            }

            return a;
          }, []);

          setDepartments((prevState) => [...prevState, ...departmentsData]);
        })
        .catch((error) => {
          console.log(error);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchData();
  }, []);

  useEffect(() => {
    const colorsData = {};
    departments.forEach((d) => {
      switch (d) {
        case "Administration":
          colorsData["Administration"] = {
            dark: "admin1",
            light: "admin2",
          };
          break;

        case "Finance":
          colorsData["Finance"] = {
            dark: "finance1",
            light: "finance2",
          };
          break;

        case "Marketing":
          colorsData["Marketing"] = {
            dark: "marketing1",
            light: "marketing2",
          };
          break;

        case "Quotations":
          colorsData["Quotations"] = {
            dark: "quotations1",
            light: "quotations2",
          };
          break;

        case "Integration":
          colorsData["Integration"] = {
            dark: "integration1",
            light: "integration2",
          };
          break;

        case "Interactive":
          colorsData["Interactive"] = {
            dark: "interactive1",
            light: "interactive2",
          };
          break;

        case "Business Development":
          colorsData["Business Development"] = {
            dark: "businessdev1",
            light: "businessdev2",
          };
          break;

        case "Service & Support":
          colorsData["Service & Support"] = {
            dark: "support1",
            light: "support2",
          };
          break;

        case "Design":
          colorsData["Design"] = {
            dark: "design1",
            light: "design2",
          };
          break;

        case "Leadership":
          colorsData["Leadership"] = {
            dark: "leadership1",
            light: "leadership2",
          };
          break;

        case "Abernathy Lighting Design":
          colorsData["Abernathy Lighting Design"] = {
            dark: "abernathydesign1",
            light: "abernathydesign2",
          };
          break;

        default:
          break;
      }
    });

    setColors(colorsData);
  }, [departments]);

  const filterDepartmentHandler = (dep) => {
    if ((department !== "All") & (dep === department)) {
      setDepartment("All");
    } else {
      setDepartment(dep);
    }
  };

  const showEmployeeBioHandler = (id) => {
    setEmployeeId(id);
    setShowModal(true);
  };

  let modal;

  if (showModal) {
    modal = (
      <div
        className={cx([
          "modal",
          "fixed top-[0] bottom-[0] left-[0] right-[0]",
          "flex justify-center items-center",
          "z-[10001]",
        ])}
        style={{ display: showModal ? "flex" : "none" }}
      >
        <div
          className={cx([
            "relative",
            "w-[70%] md:w-[50%] max-w-[520px] bg-[white] rounded-[15px]",
            "p-[15px] sm:p-[25px] !pt-0",
            "text-black",
            "z-[2]",
          ])}
        >
          <img
            src={
              selectedEmployee.avatar
                ? selectedEmployee.avatar
                : placeholderImage
            }
            className={cx([
              "w-[120px] sm:w-[160px] h-[120px] sm:h-[160px] object-cover object-center",
              "mt-[-60px] sm:mt-[-80px]",
              "block ml-[auto] mr-[auto]",
              "rounded-[50%]",
              `border-[2px] border-${
                colors[selectedEmployee.primary_department] &&
                colors[selectedEmployee.primary_department].light
              } border-solid`,
              process.env.REACT_APP_IS_EOSSITE !== "TRUE" && "border-orange",
            ])}
            alt={selectedEmployee.name}
          />

          <div
            className={cx([
              "mt-[10px]",
              "max-h-[calc(70vh-120px)] sm:max-h-[40vh] overflow-y-auto scroll-bar",
            ])}
          >
            <div className={cx(["mb-[20px]"])}>
              <div className={cx(["w-[full]", "mb-[20px]"])}>
                <div
                  className={cx([
                    "fluid-text-16px font-600 leading-[1.33] text-center",
                  ])}
                >
                  {selectedEmployee.name}
                </div>
                <div
                  className={cx([
                    "fluid-text-16px leading-[1.33]",
                    "mb-[5px] text-center",
                  ])}
                >
                  {selectedEmployee.title}
                </div>

                <ul
                  className={cx([
                    "flex justify-center items-center flex-wrap",
                    "mb-[14px]",
                    !selectedEmployee.linked_in && !selectedEmployee.email
                      ? "hidden"
                      : "block",
                  ])}
                >
                  {selectedEmployee.linked_in && (
                    <li className={cx(["mr-[5px] md:mr-[10px]"])}>
                      <a
                        className={cx([
                          "flex justify-start items-center",
                          "text-[9px] font-500",
                          "opacity-100 hover:opacity-70",
                          "transition duration-300",
                        ])}
                        target="_blank"
                        rel="noreferrer"
                        href={selectedEmployee.linked_in}
                      >
                        <img
                          src={linkedinIcon}
                          alt=""
                          className={cx(["w-[12px]"])}
                        />
                      </a>
                    </li>
                  )}

                  {selectedEmployee.email && (
                    <li className={cx(["mr-[5px] md:mr-[10px]"])}>
                      <a
                        className={cx([
                          "opacity-100 hover:opacity-70",
                          "transition duration-300",
                          "flex justify-start items-center",
                          "text-[9px] font-500",
                        ])}
                        target="_blank"
                        rel="noreferrer"
                        href={`mailto:${selectedEmployee.email}`}
                      >
                        <img
                          src={emailIcon}
                          alt=""
                          className={cx(["w-[12px]", "mr-[8px]"])}
                        />
                        {selectedEmployee.email}
                      </a>
                    </li>
                  )}
                </ul>

                <ul
                  className={cx(["flex justify-center items-start flex-wrap"])}
                >
                  {selectedEmployee.primary_department !== "" && (
                    <li
                      className={cx([
                        "mr-[12px] mb-[15px]",
                        "py-[2px] px-[4px]",
                        "border-[0.7px] border-[#dadce0] border-solid rounded-[2px]",
                        "text-[12px] font-500 text-[#606367] leading-[1]",
                        "bg-[#dadce0]",
                      ])}
                    >
                      {selectedEmployee.primary_department}
                    </li>
                  )}

                  {selectedEmployee.secondary_departments.length > 0 &&
                    selectedEmployee.secondary_departments.map((s) => {
                      return (
                        <li
                          className={cx([
                            "mr-[12px] mb-[5px]",
                            "py-[2px] px-[4px]",
                            "border-[0.7px] border-[#dadce0] border-solid rounded-[2px]",
                            "text-[12px] font-500 text-[#606367] leading-[1]",
                            "bg-[#dadce0]",
                          ])}
                          key={s}
                        >
                          {s}
                        </li>
                      );
                    })}
                </ul>
              </div>

              <div className={cx(["w-[full] mb-[20px]"])}>
                <h4
                  className={cx([
                    "text-[12px] leading-[1] font-bold uppercase text-[#606367]",
                    "mb-[5px]",
                  ])}
                >
                  Eos Lightmedia Roles
                </h4>
                <div className={cx(["text-[14px]"])}>
                  {selectedEmployee.eos_lightmedia_roles}
                </div>
              </div>
            </div>

            <h4
              className={cx([
                "text-[12px] leading-[1] font-bold uppercase text-[#606367]",
                "mb-[5px]",
              ])}
            >
              Bio
            </h4>
            <div
              className={
                cx()
                // "max-h-[20vh] sm:max-h-[20vh] overflow-y-auto scroll-bar"
              }
            >
              <div className={cx(["bio", "fluid-text-16px"])}>
                {selectedEmployee.bio
                  ? selectedEmployee.bio
                  : `${
                      selectedEmployee.name.split(" ")[0]
                    } makes light work! Bio coming soon…`}
              </div>
            </div>

            <div
              className={cx([
                "w-full",
                "flex justify-between items-center",
                "absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]",
              ])}
            >
              <button
                className={cx([
                  "relative left-[-10vw] sm:left-[-60px]",
                  "w-[7vw] sm:w-[40px]",
                ])}
                onClick={handlePrev}
                disabled={selectedEmployeeIdIndex === 0}
                style={{
                  cursor: selectedEmployeeIdIndex === 0 && "auto",
                  opacity: selectedEmployeeIdIndex === 0 && "0.4",
                }}
              >
                <img src={prevButton} alt="prev" />
              </button>
              <button
                className={cx([
                  "relative right-[-10vw] sm:right-[-60px]",
                  "w-[7vw] sm:w-[40px]",
                ])}
                onClick={handleNext}
                disabled={selectedEmployeeIdIndex === filtered.length - 1}
                style={{
                  cursor:
                    selectedEmployeeIdIndex === filtered.length - 1 && "auto",
                  opacity:
                    selectedEmployeeIdIndex === filtered.length - 1 && "0.4",
                }}
              >
                <img src={nextButton} alt="next" />
              </button>
            </div>
          </div>
        </div>

        <div
          className={cx([
            "backdrop-blur-[44.3px]",
            "bg-[#000] bg-opacity-50",
            "fixed top-[0] bottom-[0] left-[0] right-[0]",
            "z-[1]",
          ])}
          onClick={() => {
            setShowModal(false);
          }}
        ></div>
      </div>
    );
  }

  let content;
  if (loading) {
    content = process.env.REACT_APP_IS_EOSSITE === "TRUE" && <Loader />;
  } else {
    content = (
      <>
        {process.env.REACT_APP_IS_EOSSITE === "TRUE" && (
          <div
            className={cx(
              "md:flex justify-start items-start",
              "border-b-[1px] border-[#c2d1d9] border-solid"
            )}
          >
            <div className={cx(["mb-[5px] mr-0 md:mr-[50px]"])}>
              <ul className={cx(["flex justify-start items-center flex-wrap"])}>
                {departments &&
                  departments.map((d, i) => (
                    <li
                      key={i}
                      onClick={() => {
                        filterDepartmentHandler(d);
                      }}
                      className={cx([
                        `department-${colors[d] ? colors[d].light : "default"}`,
                        "transition duration-300",
                        "mr-[10px] mb-[10px]",
                        "pt-[5px] pb-[5px] pl-[12px] pr-[12px]",
                        "border-[1px] border-[#dadce0] border-solid rounded-[100px]",
                        "text-[14px] font-500 text-[#606367]",
                        "cursor-pointer",
                        department === d &&
                          `bg-${
                            colors[d] ? colors[d].light : "default"
                          }  border-${colors[d] ? colors[d].light : "default"}`,

                        department === "All" &&
                          d === "All" &&
                          `bg-default border-default
                      }`,
                      ])}
                    >
                      {d}
                    </li>
                  ))}
              </ul>
            </div>
            <ul
              className={cx([
                "sort",
                "flex justify-start sm:justify-end items-center flex-wrap sm:flex-nowrap",
                "mb-[25px] md:mb-[0] mt-[25px] md:mt-[5px]",
              ])}
            >
              {[
                {
                  sort: "first_initial",
                  label: "Alphabetical",
                },
                {
                  sort: "time_zone",
                  label: "By Timezone",
                },
              ].map((item) => {
                return (
                  <li
                    className={cx([
                      "flex justify-start items-center",
                      "sm:ml-[20px] mr-[20px] sm:mr-0",
                      "mb-[3.07vw] sm:mb-[25px]",
                      "cursor-pointer",
                    ])}
                    key={item.sort}
                    onClick={() => setSortBy(item.sort)}
                  >
                    <span
                      className={cx([
                        "inline-block content-['']",
                        "w-[20px] h-[20px]",
                        "bg-white",
                        "border-[2px] border-[black] border-solid",
                        "rounded-full",
                        "mr-[12px]",
                        "relative",
                        "transition-transform",
                        sortBy === item.sort &&
                          "border-[#8d4dae] bg-button-pattern",
                      ])}
                    ></span>
                    <span
                      className={cx([
                        "text-[15px] lg:text-[16px] leading-[1] whitespace-nowrap capitalize",
                      ])}
                    >
                      {item.label}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        <div
          className={cx([
            "w-full",
            process.env.REACT_APP_IS_EOSSITE !== "TRUE" &&
              "sm:h-[50vh] mt-[50px] sm:mt-[100px]",
          ])}
        >
          <div
            className={cx([
              process.env.REACT_APP_IS_EOSSITE !== "TRUE" &&
                "scroll-bar w-full h-full overflow-y-auto overflow-x-hidden",
            ])}
            onScroll={() => onScrollList()}
            ref={listRef}
            style={{
              WebkitMaskImage:
                process.env.REACT_APP_IS_EOSSITE === "TRUE" ||
                hideGradientBgOnList
                  ? "unset"
                  : "linear-gradient(to bottom, black calc(100% - 75px), transparent 100%)",
              maskImage:
                process.env.REACT_APP_IS_EOSSITE === "TRUE" ||
                hideGradientBgOnList
                  ? "unset"
                  : "linear-gradient(to bottom, black calc(100% - 75px), transparent 100%)",
            }}
          >
            {sortBy !== "first_initial" ? (
              Object.keys(grouped).map((k) => (
                <EmployeesList
                  department={k}
                  employees={grouped[k]}
                  colors={colors}
                  showEmployeeBioHandler={showEmployeeBioHandler}
                  key={k}
                />
              ))
            ) : (
              <EmployeesList
                employees={filtered}
                colors={colors}
                showEmployeeBioHandler={showEmployeeBioHandler}
              />
            )}
          </div>
        </div>
        {modal}
      </>
    );
  }

  return (
    <div
      className={cx([
        "page-width",
        "py-[30%] sm:py-[20%] md:py-[5%]",
        "min-h-[100vh]",
      ])}
    >
      <MeetTheTeamHeading data={props.data} />
      {content}
    </div>
  );
};

export default EmployeesContainer;
