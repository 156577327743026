import React from "react";
import cx from "classnames";
import Button from "../Button";
import awardsIcon from "../../assets/images/awards-icon.svg";
import awardsOrangeIcon from "../../assets/images/awards-orange-icon.svg";

const Awards = (props) => {
  return (
    <div className={cx(["mb-[50px]"])}>
      <h3 className={cx(["mb-[20px]", "text-[24px]", "font-bold"])}>Awards</h3>
      <ul
        className={cx([
          "mb-[20px]",
          "border-t-[0.5px] border-solid",
          process.env.REACT_APP_IS_EOSSITE === "TRUE"
            ? "border-[black]"
            : "border-[white]",
        ])}
      >
        {props.awards &&
          props.awards.map((a) => {
            return (
              <li
                key={a}
                className={cx([
                  "flex justify-start items-start",
                  "pt-[20px] pb-[20px]",
                  "border-b-[0.5px]  border-solid",
                  process.env.REACT_APP_IS_EOSSITE === "TRUE"
                    ? "border-[black]"
                    : "border-[white]",
                ])}
              >
                <img
                  className={cx(["w-[17px] mr-[15px]"])}
                  src={
                    process.env.REACT_APP_IS_EOSSITE === "TRUE"
                      ? awardsIcon
                      : awardsOrangeIcon
                  }
                  alt=""
                />
                <h3
                  className={cx([
                    "w-full",
                    "fluid-text-18px font-500 leading-[1.17] color-black",
                  ])}
                >
                  {a}
                </h3>
              </li>
            );
          })}
      </ul>
      <div className={cx(["flex justify-end items-center"])}>
        <Button
          link="/company#awards"
          anchor
          effect
          exact
          title="VIEW ALL AWARDS"
        />
      </div>
    </div>
  );
};

export default Awards;
