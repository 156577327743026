import React from "react";
import cx from "classnames";

const SiteLogo = (props) => {
  const data =
    process.env.REACT_APP_IS_EOSSITE === "TRUE"
      ? [
          {
            href: "https://www.eoslightmedia.com/",
            maskImage: true,
            logoPath: "/images/eos-lightmedia-logo.svg",
            coloured: false,
          },
        ]
      : [
          {
            href: "https://www.eoslightmedia.com/",
            maskImage: true,
            logoPath: "/images/eos-lightmedia-logo.svg",
            coloured: false,
          },
          {
            href: "/",
            maskImage: !props.showColoredALDLogo,
            logoPath: props.header
              ? "/images/ALD_logo_coloured.svg"
              : "/images/ALD_logo_coloured_black.svg",
            coloured: true,
          },
        ];

  return (
    <div
      className={cx(
        "flex justify-start items-center",
        props.footer && "mb-[20px] sm:mb-0"
      )}
    >
      {data.map((d, i) => {
        return (
          <a
            href={d.href}
            className={cx([
              process.env.REACT_APP_IS_EOSSITE !== "TRUE" && i === 0
                ? "pr-[10px]"
                : "pl-[10px]",
              process.env.REACT_APP_IS_EOSSITE !== "TRUE" &&
                i === 0 &&
                "border-r border-solid",
              props.isDarkStyle ? "border-black" : "border-white",
              props.footer && "!border-black",
              "z-[2] tansition duration-500",
            ])}
            key={i}
          >
            <div
              className={cx(
                "sitelogo-image",
                props.header && "w-[25vw] sm:w-[125px] max-w-[125px] h-[40px]",
                props.footer && "w-[100px] h-[30px]",
                "bg-contain bg-center bg-no-repeat",
                !props.isDarkStyle && !d.maskImage && "!bg-[transparent]",
                props.footer && !d.coloured && "!bg-[#000]",
                props.footer && d.coloured && "!bg-[transparent]"
              )}
              style={{
                WebkitMaskImage:
                  (props.isDarkStyle || d.maskImage) && `url(${d.logoPath})`,
                maskImage:
                  (props.isDarkStyle || d.maskImage) && `url(${d.logoPath})`,
                WebkitMaskSize: "contain",
                WebkitMaskRepeat: "no-repeat",
                maskSize: "contain",
                maskRepeat: "no-repeat",
                WebkitMaskPosition: "center",
                maskPosition: "center",
                backgroundImage:
                  !props.isDarkStyle && !d.maskImage && `url(${d.logoPath})`,
              }}
            ></div>
          </a>
        );
      })}
    </div>
  );
};

export default SiteLogo;
