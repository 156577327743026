import React from "react";
import ProjectsContainer from "../../components/Projects/ProjectsContainer";
import ScrollSnapSection from "../../components/ScrollSnapSection";
import { Helmet } from "react-helmet";

const Projects = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Work -&nbsp;
          {process.env.REACT_APP_IS_EOSSITE === "TRUE"
            ? "Eos Lightmedia"
            : "Abernathy Lighting Design"}
        </title>
      </Helmet>

      <ScrollSnapSection showScrollButton={false} id="projects">
        <ProjectsContainer />
      </ScrollSnapSection>
    </React.Fragment>
  );
};

export default Projects;
