import React from "react";
import cx from "classnames";
import TwitterIcon from "../../assets/images/tw-bl-icon.svg";
import FacebookIcon from "../../assets/images/fb-bl-icon.svg";
import InstagramIcon from "../../assets/images/insta-bl-icon.svg";
import LinkedinIcon from "../../assets/images/linkedin-bl-icon.svg";
import EmailIcon from "../../assets/images/email-bl-icon.svg";

const SocialMediaListItem = (props) => {
  let socialIcon = "";

  if (props.data.socialmedia === "twitter") {
    socialIcon = TwitterIcon;
  } else if (props.data.socialmedia === "facebook") {
    socialIcon = FacebookIcon;
  } else if (props.data.socialmedia === "instagram") {
    socialIcon = InstagramIcon;
  } else if (props.data.socialmedia === "linkedin") {
    socialIcon = LinkedinIcon;
  } else if (props.data.socialmedia === "email") {
    socialIcon = EmailIcon;
  } else {
    socialIcon = "";
  }

  return (
    <li
      className={cx([
        "flex justify-center items-center",
        "ml-[2vw] sm:ml-[25px]",
      ])}
    >
      <a
        href={props.data.link}
        target={props.data.socialmedia !== "email" ? "_blank" : "_self"}
        rel={props.data.socialmedia !== "email" ? "noreferrer" : ""}
      >
        <span className={cx(["block"])}>
          <img
            className={cx(["w-[35px] sm:w-[25px]"])}
            src={socialIcon}
            alt=""
          />
        </span>
      </a>
    </li>
  );
};

export default SocialMediaListItem;
