import axios from "axios";
import _ from "lodash";
import qs from "qs";

export const client = axios.create({
  baseURL: process.env.REACT_APP_AXIOS_BASE_URL,
  headers: {
    "Content-type": "application/json",
  },
});

export const fetchPeopleCount = () =>
  client.get("/employees/count").then((r) => _.get(r, ["data", "count"]));

export const fetchFieldByPage = (page) =>
  client
    .get(`/${page}?populate=*`)
    .then((r) => _.get(r, ["data", "data", "attributes"], {}));

export const fetchOffices = () =>
  client
    .get(`/company?populate[office_information][populate]=*`)
    .then((r) => _.get(r, ["data", "data"], {}));

export const fetchServices = () =>
  client
    .get(`/services?sort[0]=id&populate=*`)
    .then((r) => _.get(r, ["data", "data"], {}));

export const fetchServiceBySlug = (slug) =>
  client
    .get(`/services?filters[slug]=${slug}&populate=*`)
    .then((r) => _.get(r, ["data", "data", 0], {}));

export const fetchProjects = (payload) =>
  client.post("/projects", payload).then((r) => _.get(r, ["data"], []));

export const fetchProjectsFiles = (ids) =>
  client
    .get("/projects/files", { params: { ids } })
    .then((r) => _.get(r, ["data"], []));

export const fetchProjectsKeywords = () =>
  client.get("/projects/keywords").then((r) => _.get(r, ["data"], []));

export const fetchProject = (id) =>
  client.get(`/project/${id}`).then((r) => _.get(r, ["data"], []));

export const fetchAwardedProjects = () =>
  client.get("/projects/awarded").then((r) => _.get(r, ["data"], []));

export const fetchFeaturedProjects = (slug) =>
  client.get(`/projects/featured/${slug}`).then((r) => _.get(r, ["data"], []));

export const fetchRelatedProjects = (cats, id) =>
  client
    .get(`/projects/related/${qs.stringify(cats)}/${id}`)
    .then((r) => _.get(r, ["data"], []));

export const fetchProjectsCount = () =>
  client.get("/projects/count").then((r) => _.get(r, ["data", "count"]));

export const fetchAwardsCount = () =>
  client.get("/projects/awards-count").then((r) => _.get(r, ["data", "count"]));

export const sendForm = (message) =>
  client.post("/contact", message).then((r) => _.get(r, ["data", "success"]));

export default client;
