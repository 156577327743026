import React from "react";
import cx from "classnames";
import SiteLogo from "../SiteLogo/SiteLogo";
import { NavLink } from "react-router-dom";
import SocialMediaList from "./SocialMediaList";

const Copyright = (props) => {
  return (
    <div
      className={cx([
        "mt-[20px] sm:mt-[5.37vw] md:mt-[3.12vw] lg:mt-[45px] mb-[20px] sm:mb-[5.37vw] md:mb-[3.12vw] lg:mb-[45px]",
        "sm:flex justify-between items-center flex-wrap sm:flex-nowrap",
        "text-black",
      ])}
    >
      <SiteLogo
        footer
        showColoredALDLogo={process.env.REACT_APP_IS_EOSSITE !== "TRUE"}
      />
      <div className={cx(["ml-[auto] pl-[5vw]"])}>
        <NavLink to="/privacy" exact>
          <span className={cx(["fluid-text-16px text-right", "block"])}>
            Privacy
          </span>
        </NavLink>
      </div>

      <div
        className={cx(
          "w-full sm:w-auto",
          "sm:ml-[10px] mt-[10px] sm:mt-0",
          "flex justify-end items-center sm:block"
        )}
      >
        <SocialMediaList />
      </div>
    </div>
  );
};

export default Copyright;
