import React from "react";
import cx from "classnames";
import LinkListItem from "./LinkListItem";

const LinkList = (props) => (
  <ul
    className={cx([
      props.link_title ? props.link_title.replace(" ", "") : "",
      "flex justify-between items-start flex-wrap",
    ])}
  >
    {props.data.map((data, i) => (
      <LinkListItem
        key={i}
        link={data.link}
        link_title={data.link_title}
        external={data.external}
      />
    ))}
  </ul>
);

export default LinkList;
