import React, { useState, useEffect } from "react";
import _ from "lodash";
import ScrollSnapSection from "../../components/ScrollSnapSection";
import Hero from "../../components/Hero";
import Introduction from "../../components/Home/Introduction";
import ExploreProjects from "../../components/Home/ExploreProjects";
import Services from "../../components/Home/Services";
import LetsChat from "../../components/Home/LetsChat";
import backgroundImage1 from "../../assets/images/gradient_bg_01.jpg";
import backgroundImage2 from "../../assets/images/gradient_bg_02.jpg";
import backgroundImage3 from "../../assets/images/gradient_bg_03.jpg";
import { fetchFieldByPage } from "../../http";

const Home = () => {
  const [data, setData] = useState({});
  const [backgroundImage, setBackgroundImage] = useState(backgroundImage2);

  useEffect(() => {
    fetchFieldByPage("home").then((res) => {
      setData(res);
    });
  }, []);

  useEffect(() => {
    setBackgroundImage(backgroundImage);
  }, [backgroundImage]);

  const updateBackgroundImage = (data) => {
    setBackgroundImage(data);
  };

  return (
    <React.Fragment>
      <ScrollSnapSection
        backgroundColor="#000"
        backgroundVideo={_.get(
          data,
          ["hero_background_video", "data", "attributes", "url"],
          ""
        )}
        showScrollButton={true}
        showScrollIcon={true}
        showOverlay
        id="hero"
      >
        <Hero heading={_.get(data, ["hero_headline"], "")} />
      </ScrollSnapSection>

      {process.env.REACT_APP_IS_EOSSITE === "TRUE" && (
        <ScrollSnapSection
          backgroundImage={backgroundImage1}
          showScrollButton={true}
          isCardStyle
          id="introduction"
        >
          <Introduction data={_.get(data, ["introduction"], {})} />
        </ScrollSnapSection>
      )}

      {process.env.REACT_APP_IS_EOSSITE === "TRUE" && (
        <ScrollSnapSection showScrollButton={true} id="projects">
          <ExploreProjects data={_.get(data, ["projects"], {})} />
        </ScrollSnapSection>
      )}

      {process.env.REACT_APP_IS_EOSSITE === "TRUE" && (
        <ScrollSnapSection
          backgroundImage={backgroundImage}
          showScrollButton={true}
          id="services"
        >
          <Services
            mouseEnterHandler={updateBackgroundImage}
            data={_.get(data, ["services"], {})}
          />
        </ScrollSnapSection>
      )}

      {process.env.REACT_APP_IS_EOSSITE === "TRUE" && (
        <ScrollSnapSection
          backgroundImage={backgroundImage3}
          showScrollButton={true}
          isCardStyle
          id="letschat"
          scrollUp={true}
        >
          <LetsChat data={_.get(data, ["lets_chat"], {})} />
        </ScrollSnapSection>
      )}
    </React.Fragment>
  );
};

export default Home;
