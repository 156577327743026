import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";

import _ from "lodash";
import { fetchAwardedProjects } from "../../http";
import MainHeading from "../MainHeading";
import useWindowDimensions from "../../Utils/useWindowDimensions";

const Awards = (props) => {
  const { width } = useWindowDimensions();
  const [projects, setProjects] = useState([]);
  const [isEnterMouse, setIsEnterMouse] = useState(true);
  const [hoveredItemIndex, setHoveredItemIndex] = useState(0);
  const [hoveredAward, setHoveredAward] = useState("");
  const [hideGradientBgOnList, setHideGradientBgOnList] = useState(false);

  const handleHover = (state, index, award) => {
    setIsEnterMouse(state);
    setHoveredItemIndex(index);
    setHoveredAward(award);
  };

  const ref = useRef(null);

  /*
   * get awards data
   */
  useEffect(() => {
    fetchAwardedProjects().then((res) => {
      const projectsData = res.map((p, i) => {
        return {
          ...p,
          index: i,
        };
      });

      setProjects(projectsData);
    });
  }, []);

  useEffect(() => {
    setHoveredAward(_.get(projects, [0, "awards", 0], ""));
  }, [projects]);

  const images = projects.map((a, i) => {
    return (
      <div
        className={cx([
          "image",
          hoveredItemIndex === a.index && isEnterMouse ? "active" : "",
        ])}
        key={i}
      >
        <NavLink to={"/work/" + a.id}>
          <div className={cx("h-[calc(55vh-20px)] w-[calc((55vh-20px)/1.35)]")}>
            <img
              className={cx(["w-full", "h-full", "object-cover"])}
              alt=""
              src={a.thumbnail}
            />
          </div>
          <span
            className={cx(["w-full block", "fluid-text-16px", "mt-[10px]"])}
          >
            {a.name}
          </span>
        </NavLink>
      </div>
    );
  });

  // Change the format from "2021 - IES Illumination Awards –  Merit Award" to "IES Illumination Awards –  Merit Award"
  const formatTitle = (title) => {
    const [year, organization, award] = title.replace("–", "-").split("-");

    const newTitle = `${organization}  
    ${award}`;

    return year && organization && award ? newTitle : title;
  };

  /*
   * fade the containers at the bottom when it's scrollable
   */
  const listRef = useRef();
  const onScrollList = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      setHideGradientBgOnList(scrollTop + clientHeight >= scrollHeight - 10);
    }
  };

  useEffect(() => {
    if (listRef.current && listRef.current.children[0]) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      setHideGradientBgOnList(
        clientHeight > listRef.current.children[0].clientHeight ||
          scrollTop + clientHeight >= scrollHeight - 10
      );
    }
  }, [width, projects]);

  return (
    <div
      className={cx([
        "page-width",
        "flex justify-center items-center",
        "pt-[25%] md:pt-0 pb-[25%] md:pb-0",
        "min-h-[100vh]",
      ])}
    >
      <div className="w-full">
        <div
          className={cx(["relative", "mb-[10%] sm:mb-[5%] mt-0 sm:mt-[-5%]"])}
        >
          <MainHeading
            title={_.get(props.data, ["headline"], "")}
            medium
            gradientOutline
            gradientPurple={process.env.REACT_APP_IS_EOSSITE === "TRUE"}
            customStylesOutline="text-[#1c3ba1] ml-[-4vw] sm:ml-[-3.59vw] md:ml-[-3.47vw] lg:ml-[-50px] !whitespace-nowrap"
            customStylesTitle={`${
              process.env.REACT_APP_IS_EOSSITE === "TRUE"
                ? "!text-black"
                : "!text-white"
            }`}
          />

          <p
            className={cx([
              "w-full",
              "mt-[10vw] sm:mt-[6vw] md:mt-[-4.861vw] lg:mt-[-70px]",
              "text-left leading-[normal] text-[4.82vw] sm:text-[3.47vw] md:text-[1.66vw] lg:text-[24px]",
              "relative z-[2]",
            ])}
          >
            {_.get(props.data, ["strapline"], "")}
          </p>
        </div>

        <div
          className={cx([
            "awards-container",
            "relative",
            "block md:flex md:justify-between md:items-start",
            "h-[55vh]",
          ])}
        >
          <div className={cx("relative", "w-full md:w-[50%] h-full")}>
            <div
              className={cx(
                "w-full",
                "absolute left-[50%] bottom-[2.5%] md:bottom-[5%] translate-x-[-50%]",
                hideGradientBgOnList ? "opacity-0" : "opacity-100",
                "transition duration-300 ease-out"
              )}
            >
              <span
                className={cx(
                  "content-[''] block w-[14px] h-[23px]",
                  "m-auto relative",
                  "border-[1px] border-solid rounded-[40px] shadow-[0 1.6px 1.6px 0 rgba(0, 0, 0, 0.25)]",
                  "before:content-[''] before:block before:w-[3px] before:h-[3px] before:rounded-[50%] before:absolute before:left-[50%] before:translate-x-[-50%] before:top-[5px]",
                  "after:content-[''] after:w-[0.4px] after:h-[11px] after:absolute after:top-[50%] after:left-[50%] after:transalte-x-[-50%] after:translate-y-[-50%]",
                  process.env.REACT_APP_IS_EOSSITE === "TRUE"
                    ? "border-black before:bg-black after:bg-black"
                    : "border-white before:bg-white after:bg-white"
                )}
              ></span>
              <span
                className={cx(
                  "block mt-[5px] md:mt-[10px]",
                  "text-[14px] md:text-[16px] text-center",
                  process.env.REACT_APP_IS_EOSSITE === "TRUE"
                    ? "text-black"
                    : "text-white"
                )}
              >
                Scroll for more projects
              </span>
            </div>

            <div
              className={cx([
                "award-list scroll-bar",
                "w-full h-full",
                "pr-[45px] md:pr-[5%] md:mr-[5%]",
                "overflow-y-auto overflow-x-hidden",
              ])}
              onScroll={() => onScrollList()}
              ref={listRef}
              style={{
                WebkitMaskImage: hideGradientBgOnList
                  ? "unset"
                  : "linear-gradient(to top, transparent 20%, black 35%)",
                maskImage: hideGradientBgOnList
                  ? "unset"
                  : "linear-gradient(to top, transparent 20%, black 35%)",
              }}
            >
              <div>
                {projects.map((p, i) => {
                  return p.awards.map((a) => {
                    return (
                      <div
                        key={a}
                        className={cx([
                          "award-item",
                          "mb-[20px]",
                          hoveredAward === a && isEnterMouse && "active",
                        ])}
                        onMouseEnter={() => handleHover(true, p.index, a)}
                      >
                        <NavLink to={"/work/" + p.id}>
                          <div
                            className={cx([
                              "award-title-wrapper",
                              "relative",
                              "leading-[1]",
                              "fluid-text-32px",
                            ])}
                          >
                            <span className={cx(["award-title", "font-600"])}>
                              {formatTitle(a)}
                            </span>
                            <span
                              className={cx([
                                "award-title-outline-text",
                                process.env.REACT_APP_IS_EOSSITE === "TRUE"
                                  ? "light"
                                  : "dark",
                                "font-normal",
                              ])}
                            >
                              {formatTitle(a)}
                            </span>
                            <span
                              className={cx([
                                `award-year ${
                                  process.env.REACT_APP_IS_EOSSITE === "TRUE"
                                    ? "purple"
                                    : "orange"
                                }`,
                                "absolute pt-[0.5%] pl-[10px]",
                                "text-[16px] font-bold tracking-[1] text-black",
                              ])}
                            >
                              {!isNaN(a.substring(0, 4)) && a.substring(0, 4)}
                            </span>
                          </div>
                        </NavLink>
                      </div>
                    );
                  });
                })}
              </div>
            </div>
          </div>

          <div className={cx(["award-media", "hidden md:block", "w-[45%]"])}>
            <div
              ref={ref}
              className={cx(["w-full", "relative overflow-hidden"])}
            >
              {images}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Awards;
