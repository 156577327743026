import React, { Fragment } from "react";
import cx from "classnames";
import ContactListItem from "./ContactListItem";

const ContactList = () => {
  const data =
    process.env.REACT_APP_IS_EOSSITE === "TRUE"
      ? [
          {
            title: "GENERAL INQUIRIES",
            email: "info@eoslightmedia.com",
            gradient: "type1",
          },
          {
            title: "SUPPORT",
            email: "support@eoslightmedia.com",
            gradient: "type2",
          },
        ]
      : [
          {
            title: "GENERAL INQUIRIES",
            email: "info@ald.lighting",
            gradient: "type3",
          },
          {
            contactOfEosFromALDSite: true,
          },
        ];

  return (
    <ul className={cx(["block md:flex md:justify-between md:items-start"])}>
      {data.map(function (data, i) {
        return (
          <ContactListItem
            key={i}
            index={i}
            title={data.title}
            email={data.email}
            gradient={data.gradient}
            contactOfEosFromALDSite={data.contactOfEosFromALDSite}
          />
        );
      })}
    </ul>
  );
};

export default ContactList;
