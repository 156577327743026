import React from "react";
import cx from "classnames";
import Field from "./Field";
import Tag from "./Tag";

const Details = (props) => {
  return (
    <div
      className={cx([
        "w-full",
        "block sm:flex sm:justify-between sm:items-start flex-wrap md:flex-nowrap",
      ])}
    >
      <div
        className={cx([
          "w-full md:w-[50%]",
          "pr-[0] md:pr-[10%] mb-[50px] md:mb-[0]",
        ])}
      >
        <h2
          className={cx([
            "leading-[1.08] font-bold text-[10.25vw] sm:text-[5.99vw] md:text-[3.47vw] lg:text-[50px]",
            process.env.REACT_APP_IS_EOSSITE === "TRUE"
              ? "text-black"
              : "text-white",
          ])}
        >
          {props.project && props.project.name}
        </h2>
      </div>

      <div className={cx(["w-full sm:w-[50%] md:w-[25%]"])}>
        {props.project && props.project.client && (
          <Field title="CLIENT" detail={props.project.client} />
        )}

        {props.project && props.project.size && (
          <Field title="SIZE" detail={props.project.size} />
        )}

        {props.project && props.project.completionDate !== "0" && (
          <Field
            title="COMPLETION DATE"
            detail={props.project.completionDate}
          />
        )}

        <Field
          title="LOCATION"
          detail={props.project && props.project.location}
        />
      </div>
      <div className={cx(["w-full sm:w-[50%] md:w-[25%]"])}>
        <Field
          title="OUR ROLES"
          detail={
            props.project.servicesCategory &&
            props.project.servicesCategory.map((s) => (
              <Tag key={s.name} type="serviceCategory" value={s} />
            ))
          }
        />

        <Field
          title="SERVICE TYPE"
          detail={
            props.project.serviceType &&
            props.project.serviceType.map((s) => (
              <Tag key={s.name} type="serviceType" value={s} />
            ))
          }
        />
        <Field
          title="PROJECT CATEGORY"
          detail={
            props.project.projectCategory &&
            props.project.projectCategory.map((s) => (
              <Tag key={s.name} type="projectCategory" value={s} />
            ))
          }
        />
      </div>
    </div>
  );
};

export default Details;
