import React from "react";
import ScrollSnapSection from "../../components/ScrollSnapSection";
import ContactForm from "../../components/Contact/ContactForm";
import backgroundImage1 from "../../assets/images/gradient_bg_03.jpg";
import backgroundImageOrange from "../../assets/images/gradient_bg_orange.jpg";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Contact -&nbsp;
          {process.env.REACT_APP_IS_EOSSITE === "TRUE"
            ? "Eos Lightmedia"
            : "Abernathy Lighting Design"}
        </title>
      </Helmet>

      <ScrollSnapSection
        backgroundImage={
          process.env.REACT_APP_IS_EOSSITE === "TRUE"
            ? backgroundImage1
            : backgroundImageOrange
        }
        showScrollButton={false}
        id="contact"
      >
        <ContactForm />
      </ScrollSnapSection>
    </React.Fragment>
  );
};

export default Contact;
