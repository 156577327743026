import React, { useState } from "react";
import cx from "classnames";
import { sendForm } from "../../http";

import MainHeading from "../MainHeading";

import Button from "../Button";

const ContactForm = (props) => {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [department, setDepartment] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  // const [errorMessages, setErrorMessages] = useState("");
  const [success, setSuccess] = useState(false);

  const departments = ["Sales", "Support & General"];

  const submitForm = (e) => {
    e.preventDefault();

    sendForm({ name, company, department, email, message }).then((success) => {
      if (success) {
        setSuccess(success);
        setName("");
        setCompany("");
        setDepartment("");
        setEmail("");
        setMessage("");
      }
    });
  };

  const fields = [
    {
      label: "Hey, my name is",
      el: (
        <input
          className={cx(["form-input", "name"])}
          type="text"
          placeholder="FIRST &amp; LAST NAME"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      ),
    },
    {
      label: "and I work for",
      el: (
        <input
          className={cx(["form-input", "company"])}
          type="text"
          placeholder="COMPANY"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          required
        />
      ),
    },
    process.env.REACT_APP_IS_EOSSITE === "TRUE" && {
      label: "I’m looking for",
      el: (
        <>
          <select
            className={cx(["form-input", "department"])}
            onChange={(e) => setDepartment(e.target.value)}
            required
            defaultValue=""
          >
            <option value="" disabled>
              SELECT DEPARTMENT
            </option>
            {departments.map((d) => {
              return (
                <option value={d} key={d}>
                  {d}
                </option>
              );
            })}
          </select>

          <span className={cx(["fluid-text-32px dot"])}>.</span>
        </>
      ),
    },

    {
      label: "You can reach me at",
      el: (
        <>
          <input
            className={cx(["form-input", "email"])}
            type="email"
            placeholder="EMAIL ADDRESS"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <span className={cx(["fluid-text-32px dot"])}>.</span>
        </>
      ),
    },

    {
      label: "Here's my question:",
      el: (
        <textarea
          className={cx(["form-input", "message"])}
          placeholder=""
          value={message}
          rows={3}
          onChange={(e) => setMessage(e.target.value)}
        />
      ),
    },

    {
      label: "Thank you.",
    },
  ];

  return (
    <React.Fragment>
      <div
        className={cx([
          "page-width",
          "min-h-[100vh]",
          "flex justify-center items-center",
          "py-[30%] sm:py-[20%] md:py-[5%]",
        ])}
      >
        <div className={cx(["!max-w-[925px] w-full ml-auto mr-auto"])}>
          <div className="md:mb-[-50px]">
            <MainHeading
              title={success ? "Thank you" : "Drop us a note"}
              medium
              customStylesOutline="ml-[-4vw] sm:ml-[-3.59vw] md:ml-[-9.37vw] lg:ml-[-135px]"
            />
          </div>
          <div
            className={cx([
              "w-full",
              "relative",
              "mt-[15px] md:mt-[30px]",
              "relative",
            ])}
          >
            {success ? (
              <>
                <div
                  className={cx([
                    "mb-[40px]",
                    "text-white fluid-text-32px font-500",
                  ])}
                >
                  <span
                    className={cx([
                      "block",
                      "mt-[40px] md:mt-[0] mb-[40px] md:mb-[50px]",
                    ])}
                  >
                    We’ll get back to you as soon as we can!
                  </span>
                  <span>
                    In the meantime, you can check out our award winning
                    projects.
                  </span>
                </div>
                <Button title="EXPLORE PROJECTS" link="/work" exact />
              </>
            ) : (
              <form className="contact-form" onSubmit={submitForm}>
                <div className="mb-[40px]">
                  {fields.map((f) => {
                    if (f) {
                      return (
                        <label
                          className={cx(["form-field-wrapper"])}
                          key={f.label}
                        >
                          <div
                            className={cx([
                              "form-label",
                              "fluid-text-31px",
                              "mr-[10px]",
                            ])}
                          >
                            {f.label}
                          </div>
                          {f.el}
                        </label>
                      );
                    }
                  })}
                </div>
                <Button title="SUBMIT" type="submit" />
              </form>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactForm;
