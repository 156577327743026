import React from "react";
import cx from "classnames";

import NavigationListItem from "./NavigationListItem";

const links =
  process.env.REACT_APP_IS_EOSSITE === "TRUE"
    ? [
        {
          link: "/",
          title: "HOME",
          exact: true,
        },
        {
          link: "/services",
          title: "SERVICES",
          exact: true,
        },
        {
          link: "/work",
          title: "PROJECTS",
          exact: true,
        },
        {
          link: "/company",
          title: "COMPANY",
          exact: true,
        },
        {
          link: "/team",
          title: "TEAM",
          exact: true,
        },
        {
          link: "/contact",
          title: "CONTACT US",
          exact: true,
        },
      ]
    : [
        {
          link: "/",
          title: "HOME",
          exact: true,
        },
        {
          link: "/work",
          title: "PROJECTS",
          exact: true,
        },
        {
          link: "/company",
          title: "COMPANY",
          exact: true,
        },
        {
          link: "/contact",
          title: "CONTACT US",
          exact: true,
        },
      ];

const NavigationList = (props) => {
  return (
    <ul
      className={cx([
        props.isHamburgerMenu ? "" : "blur-effect",
        "relative",
        "md:h-full",
        "md:flex md:justify-between md:items-center",
        "z-[1]",
      ])}
    >
      {links.map((d) => (
        <NavigationListItem
          key={d.title}
          link={d.link}
          exact={d.exact}
          title={d.title}
          closeMenu={props.closeMenu}
        />
      ))}
    </ul>
  );
};

export default NavigationList;
