import React, { useState, useEffect } from "react";
import cx from "classnames";

import ScrollSnapSection from "../ScrollSnapSection";
import OfficeList from "./OfficeList";
import FooterHeading from "./FooterHeading";
import LinkList from "./LinkList";
import Copyright from "./Copyright";
import ContactList from "./ContactList";

const Footer = (props) => {
  const [serviceLinks, setServiceLinks] = useState([]);

  useEffect(() => {
    const links = props.services.map((s) => ({
      link: "/services/" + s.attributes.slug,
      link_title: s.attributes.title,
    }));

    setServiceLinks(links);
  }, [props.services]);

  const pageLinks =
    process.env.REACT_APP_IS_EOSSITE === "TRUE"
      ? [
          {
            link: "/company",
            link_title: "Company",
            external: false,
          },
          {
            link: "/team",
            link_title: "Team",
            external: false,
          },
          {
            link: "/work",
            link_title: "Projects",
            external: false,
          },
          {
            link: "https://www.linkedin.com/company/eos-lightmedia/",
            link_title: "Careers",
            external: true,
          },
        ]
      : [
          {
            link: "/work",
            link_title: "Projects",
            external: false,
          },
          {
            link: "/company",
            link_title: "Company",
            external: false,
          },
          {
            link: "/contact",
            link_title: "Contact Us",
            external: false,
          },
        ];

  return (
    <ScrollSnapSection
      backgroundColor="#f5f5f5"
      showScrollButton={false}
      id="footer"
    >
      <div className="page-width">
        <h2
          className={cx([
            "footer-title",
            "w-full sm:max-w-[50vw] md:w-[31.94vw] lg:max-w-[460px]",
            "mb-[10.25vw] sm:mb-[7.67vw] md:mb-[4.51vw] lg:mb-[65px]",
            "text-black leading-[1] font-light",
            "fluid-text-64px",
          ])}
          style={{
            textShadow: "0 4px 10px #ffffff40",
          }}
        >
          Got a project? Let’s talk
        </h2>
        <ContactList />

        <div
          className={cx([
            "pb-[7.69vw] sm:pb-[5.99vw] md:pb-[5vh] lg:pb-[40px]",
            "border-b-[1px] border-[black] border-solid",
            "flex justify-start items-start flex-wrap md:-flex-nowrap",
          ])}
        >
          <div
            className={cx([
              "mb-[13vw] sm:mb-[5vw] md:mb-[unset]",
              "w-full md:w-[60%]",
            ])}
          >
            <FooterHeading title="OFFICES" />
            <OfficeList />
          </div>

          <div
            className={cx([
              "w-full md:w-[40%]",
              "sm:flex justify-start items-start",
            ])}
          >
            {process.env.REACT_APP_IS_EOSSITE === "TRUE" && (
              <div
                className={cx([
                  "pr-0 sm:pr-[10%]",
                  "mb-[13vw] sm:mb-[5vw] md:mb-[unset]",
                  "w-full",
                ])}
              >
                <FooterHeading title="SERVICES" bold />
                <LinkList data={serviceLinks} />
              </div>
            )}

            <div
              className={cx([
                "pr-0 sm:pr-[10%]",
                "mb-[13vw] sm:mb-[5vw] md:mb-[unset]",
                "w-full",
              ])}
            >
              <FooterHeading title="ABOUT US" bold />
              <LinkList data={pageLinks} />
            </div>
          </div>
        </div>

        <Copyright />
      </div>
    </ScrollSnapSection>
  );
};

export default Footer;
