import React from "react";
import _ from "lodash";
import cx from "classnames";

import MainHeading from "../MainHeading";

const OurStory = (props) => {
  return (
    <div className="page-width">
      <MainHeading title={_.get(props.data, ["headline"], "")} large />
      <div
        className={cx([
          "text-container",
          "w-full md:w-[80%] lg:w-[60%]",
          "md:mt-[5.99vw] lg:mt-[50px] md:ml-auto",
          "text-white",
        ])}
      >
        <p
          className={cx([
            "mb-[7.69vw] sm:mb-[5.99vw] md:mb-[5vh] lg:mb-[40px]",
            "text-white leading-[normal] whitespace-pre-wrap",
            "fluid-text-18px",
          ])}
        >
          {_.get(props.data, ["strapline"], "")}
        </p>
      </div>
    </div>
  );
};

export default OurStory;
