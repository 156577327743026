import React from "react";
import _ from "lodash";
import cx from "classnames";

import MainHeading from "../MainHeading";

const AboutEOS = (props) => {
  return (
    <div className="page-width">
      <MainHeading
        title={_.get(props.data, ["headline"], "")}
        large
        gradientOutline
        gradientPurple={process.env.REACT_APP_IS_EOSSITE === "TRUE"}
      />

      <div
        className={cx([
          "text-container",
          "w-full md:w-[60%]",
          "md:ml-auto md:mt-[-10vh]",
          "text-white",
          "relative z-[20]",
        ])}
      >
        <p
          className={cx([
            "mb-[5.76vw] sm:mb-[4.5vw] md:mb-[3.75vh] lg:mb-[30px]",
            "text-white leading-[normal] whitespace-pre-wrap",
            "fluid-text-18px",
          ])}
        >
          {_.get(props.data, ["strapline"], "")}
        </p>
        <a
          className={cx(
            "uppercase text-[15px] font-[500] underline",
            "hover:opacity-80",
            "transition-all duration-300"
          )}
          style={{ textUnderlineOffset: "8px" }}
          href={_.get(props.data, ["button_link"], "")}
          target="_blank"
          rel="noreferrer"
        >
          {_.get(props.data, ["button_name"], "")}
        </a>
      </div>
    </div>
  );
};

export default AboutEOS;
