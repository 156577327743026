import React from "react";
import cx from "classnames";

const Overview = (props) => {
  return (
    <div className={cx(["mb-[60px] md:mb-[120px]"])}>
      <h3 className={cx(["mb-[20px]", "text-[24px]", "font-bold"])}>
        Overview
      </h3>
      <p className={cx(["fluid-text-16px"])}>{props.overview}</p>
    </div>
  );
};

export default Overview;
