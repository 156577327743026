import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  // window.scrollTo doesn't work on elements with scroll-snap effect
  // disable scroll snap first and enable again after page scrolls to top

  useEffect(() => {
    const ele = document.getElementsByClassName("enable-snap");
    ele && ele[0] && ele[0].classList.add("disable-snap");

    window.scrollTo(0, 0);

    setTimeout(() => {
      ele && ele[0] && ele[0].classList.remove("disable-snap");
    }, 100);
  }, [pathname]);

  return null;
}
