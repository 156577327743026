import React from "react";
import cx from "classnames";

const FooterHeading = (props) => {
  return (
    <h3
      className={cx([
        "footer-heading",
        "mb-[8px]",
        "text-black uppercase text-[14px]",
        props.bold && "font-bold",
      ])}
    >
      {props.title}
    </h3>
  );
};

export default FooterHeading;
