import React from "react";
import cx from "classnames";
import SocialMediaListItem from "./SocialMediaListItem";

const SocialMediaList = () => {
  const data =
    process.env.REACT_APP_IS_EOSSITE === "TRUE"
      ? [
          {
            socialmedia: "twitter",
            link: "https://twitter.com/eoslightmedia",
          },
          {
            socialmedia: "facebook",
            link: "https://www.facebook.com/eoslightmedia/",
          },
          {
            socialmedia: "instagram",
            link: "https://www.instagram.com/eoslightmedia/",
          },
          {
            socialmedia: "linkedin",
            link: "https://ca.linkedin.com/company/eos-lightmedia",
          },
        ]
      : [
          {
            socialmedia: "email",
            link: "mailto:info@ald.lighting",
          },
          {
            socialmedia: "facebook",
            link: "https://www.facebook.com/ALDlighting/",
          },
        ];

  return (
    <ul className={cx(["flex justify-start sm:justify-between items-center"])}>
      {data.map(function (data, i) {
        return <SocialMediaListItem key={i} data={data} />;
      })}
    </ul>
  );
};

export default SocialMediaList;
