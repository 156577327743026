import React from "react";
import cx from "classnames";

import { NavLink } from "react-router-dom";

const NavigationListItem = (props) => {
  return (
    <li
      className={cx([
        "navigation-list-item",
        "relative",
        "md:mr-[20px] md:ml-[20px]",
        "text-center whitespace-nowrap",
        "z-[2]",
      ])}
    >
      <NavLink
        to={props.link}
        exact={props.exact}
        onClick={props.closeMenu && props.closeMenu}
      >
        {props.title}
      </NavLink>

      <div className={cx(["navigation-list-item-border"])}></div>
    </li>
  );
};

export default NavigationListItem;
