import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useHistory } from "react-router";
import { useQuery } from "../../Utils/useQuery";

export const Filter = (props) => {
  const query = useQuery();
  const history = useHistory();
  const [value, setValue] = useState(query.getAll(""));
  const [isToggled, setIsToggled] = useState(true);
  const serviceTypeIds = ["277", "278", "279", "280"];

  useEffect(() => {
    setValue(query.getAll("project_keywords"));
  }, [query]);

  const toggleHandler = () => {
    setIsToggled(!isToggled);
  };

  let label;
  if (props.label === "serviceCategory") {
    label = "OUR ROLES";
  } else if (props.label === "serviceType") {
    label = "SERVICE TYPE";
  } else if (props.label === "projectCategory") {
    label = "PROJECT CATEGORY";
  } else {
    label = props.label;
  }

  return (
    <div
      onClick={toggleHandler}
      className={cx(["w-full sm:w-[50%] md:w-full", "mb-[20px] md:mb-[30px]"])}
    >
      <h4
        className={cx([
          "toggle-title",
          "font-bold text-[16px] uppercase",
          "mb-[5px]",
          "flex justify-between items-center",
          "cursor-pointer",
        ])}
      >
        {label}
        <span className={cx(["toggle-btn", isToggled && "close"])}></span>
      </h4>

      <div
        className="toggle-content"
        style={{ display: isToggled ? "block" : "none" }}
      >
        {props.values &&
          props.values.map((a) => {
            return (
              a.count > 0 && (
                <label
                  key={a.name}
                  className={cx([
                    "flex justify-start items-center",
                    "mb-[5px]",
                    "cursor-pointer",
                  ])}
                >
                  <input
                    className={cx(["input"])}
                    type="checkbox"
                    checked={value.indexOf(a.id.toString()) > -1}
                    onChange={() => {
                      if (value.indexOf(a.id.toString()) > -1) {
                        query.delete("project_keywords");
                        value.forEach((v) => {
                          if (v !== a.id.toString()) {
                            query.append("project_keywords", v);
                          }
                        });
                      } else {
                        // check if selected item is service type
                        if (serviceTypeIds.includes(a.id.toString())) {
                          //  if other service type item is alreday selected, remove it from the query
                          let other;
                          value.some((v) => {
                            if (serviceTypeIds.indexOf(v) !== -1) other = v;
                          });

                          if (other) {
                            query.delete("project_keywords");
                            value.forEach((v) => {
                              if (v !== other.toString()) {
                                query.append("project_keywords", v);
                              }
                            });
                          }
                        }

                        query.append("project_keywords", a.id);
                      }

                      history.replace({
                        search: `?${query.toString()}`,
                      });
                    }}
                  />

                  <span
                    className={cx(
                      `checkbox ${
                        process.env.REACT_APP_IS_EOSSITE === "TRUE"
                          ? "purple"
                          : "orange"
                      }`,
                      "inline-block content=[''] min-w-[12px] min-h-[12px] w-[12px] h-[12px]",
                      "border-solid rounded-[50%]",
                      process.env.REACT_APP_IS_EOSSITE === "TRUE"
                        ? "bg-white  border-black border-[2px]"
                        : "bg-black  border-white border-[1px]",
                      "mr-[12px] relative transition duration-300"
                    )}
                  ></span>
                  <span
                    className={cx([
                      "text-[15px] md:text-[1.2vw] lg:text-[16px] leading-[1] md:whitespace-nowrap",
                    ])}
                  >
                    {a.name}
                  </span>
                  <span
                    className={cx([
                      "inline-block",
                      "text-[15px] md:text-[1.2vw] lg:text-[16px] md:whitespace-nowrap",
                      "ml-[5px]",
                    ])}
                  >
                    ({a.count})
                  </span>
                </label>
              )
            );
          })}
      </div>
    </div>
  );
};
