import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import cx from "classnames";
import SiteLogo from "../SiteLogo/SiteLogo";
import NavigationList from "./NavigationList";
import HamburgerMenu from "./HamburgerMenu";

const Header = (props) => {
  const location = useLocation();
  const [isDarkStyle, setIsDarkStyle] = useState(false);
  const [isStickyStyle, setStickyStyle] = useState(false);
  const [hideMenu, setHideMenu] = useState(false);

  const onClickHandler = () => {
    setHideMenu(!hideMenu);
  };

  useEffect(() => {
    if (process.env.REACT_APP_IS_EOSSITE === "TRUE") {
      setIsDarkStyle(props.scrolled);
    } else {
      setIsDarkStyle(props.isDarkStyleOnHeaderForALD);
    }
  }, [props.scrolled, props.isDarkStyleOnHeaderForALD]);

  useEffect(() => {
    setStickyStyle(!props.isStickyStyleDisabled && props.scrolled);
  }, [props.isStickyStyleDisabled, props.scrolled]);

  useEffect(() => {
    if (isStickyStyle && !hideMenu) {
      setHideMenu(true);
    } else if (isStickyStyle && hideMenu) {
      setHideMenu(true);
    } else {
      setHideMenu(false);
    }
    // eslint-disable-next-line
  }, [isStickyStyle]);

  return (
    <React.Fragment>
      <header
        className={cx([
          "header",
          "mobile-nav-menu",
          "w-full h-[60px]",
          "flex md:hidden justify-center items-stretch",
          "fixed top-[0[] left-[0] w-full",
          "border-b-[1px] border-[white] border-solid",
          "z-[10000]",
        ])}
        id="header"
      >
        <div
          className={cx([
            "page-width",
            "w-full flex justify-between items-center",
          ])}
        >
          <SiteLogo
            header
            showColoredALDLogo={process.env.REACT_APP_IS_EOSSITE !== "TRUE"}
          />
          <HamburgerMenu />
        </div>
      </header>

      <header
        className={cx([
          "desktop-nav-menu",
          "w-full h-[60px]",
          "hidden md:flex justify-center items-stretch",
          "absolute top-[0[] left-[0] w-full",
          "z-[10000]",
          isDarkStyle ||
          (process.env.REACT_APP_IS_EOSSITE === "TRUE" &&
            location.pathname.replaceAll("/", "") === "work") ||
          (process.env.REACT_APP_IS_EOSSITE === "TRUE" &&
            location.pathname.replaceAll("/", "") === "team") ||
          (process.env.REACT_APP_IS_EOSSITE === "TRUE" &&
            location.pathname.replaceAll("/", "") === "privacy")
            ? "dark-style"
            : "",
        ])}
        id="header"
      >
        <div
          onClick={onClickHandler}
          className={cx([
            "nav-button-wrapper",
            "w-[45px] h-[45px]",
            "absolute left-[20px] top-[50%] translate-y-[-50%]",
            "flex justify-center items-center",
            "z-[2]",
            "p-[10px]",
            "border-[1px] border-[white] border-solid",
            "rounded-[50%]",
            "cursor-pointer",
            hideMenu ? "close" : "open",
            props.isStickyStyleDisabled
              ? "opacity-0 !pointer-events-none"
              : "opacity-100",
            "tansition duration-300 ease",
          ])}
        >
          <div className="nav-button">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div
          className={cx([
            "menu",
            "page-width",
            "w-full flex justify-between items-center",
            hideMenu ? "hide" : "",
          ])}
        >
          <SiteLogo
            header
            showColoredALDLogo={
              process.env.REACT_APP_IS_EOSSITE !== "TRUE" &&
              location.pathname.replaceAll("/", "") !== "company" &&
              process.env.REACT_APP_IS_EOSSITE !== "TRUE" &&
              location.pathname.replaceAll("/", "") !== "contact"
            }
            isDarkStyle={isDarkStyle}
          />
          <div className="white-line"></div>
          <NavigationList />
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
