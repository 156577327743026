import React from "react";
import cx from "classnames";
import _ from "lodash";

import MainHeading from "../MainHeading";
import Button from "../Button";
import ClientLogoList from "./ClientLogoList";

const LetsChat = (props) => {
  return (
    <React.Fragment>
      <div className="page-width">
        <MainHeading title={_.get(props.data, ["headline"], "")} large />
        <div
          className={cx([
            "w-full md:w-[50%]",
            "md:mt-[5.99vw] lg:mt-[50px] md:ml-auto",
            "text-white",
            "relative",
          ])}
        >
          <p
            className={cx([
              "mb-[7.69vw] sm:mb-[5.99vw] md:mb-[5vh] lg:mb-[40px]",
              "text-white leading-[normal] whitespace-pre-wrap",
              "fluid-text-24px",
            ])}
          >
            {_.get(props.data, ["strapline"], "")}
          </p>
          <Button
            link={_.get(props.data, ["button_link"], "")}
            exact
            title={_.get(props.data, ["button_name"], "")}
          />
        </div>
      </div>

      <div
        className={cx([
          "relative pb-[2vh] sm:pb-[4.79vw] md:pb-[3vh] lg:pb-[40px]",
          "w-full mt-auto",
          "mt-[auto]",
        ])}
      >
        <ClientLogoList />
      </div>
    </React.Fragment>
  );
};

export default LetsChat;
