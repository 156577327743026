import React from "react";
import cx from "classnames";

const OfficeListItem = (props) => {
  return (
    <li
      className={cx([
        "office-item",
        "w-full pr-[unset] md:pr-[10%]",
        "mb-[20px] sm:mb-[5.37vw] md:mb-[3.12vw] lg:mb-[45px] md:ml-[auto]",
      ])}
    >
      <h4
        className={cx([
          "office-address1",
          "text-black leading-[1] font-bold text-[4.82vw] sm:text-[3.47vw] md:text-[1.66vw] lg:text-[24px]",
          "mt-[5px] mb-[5px]",
        ])}
      >
        {props.address1}
      </h4>
      <a
        className={cx(["office-phone", "text-black fluid-text-18px"])}
        href={`tel:${props.phone}`}
      >
        <span>Tel: {props.phone}</span>
      </a>
      <div className={cx(["office-address2", "text-black fluid-text-18px"])}>
        {props.address2}
      </div>
    </li>
  );
};

export default OfficeListItem;
