import React from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";

const LinkListItem = (props) => {
  let content;
  if (props.external) {
    content = (
      <a href={props.link} target="_blank" rel="noreferrer">
        <span
          className={cx([
            "text-black fluid-text-16px sm:text-[2.15vw] md:text-[1.25vw] lg:text-[18px] whitespace-nowrap",
          ])}
        >
          {props.link_title}
        </span>
      </a>
    );
  } else {
    content = (
      <NavLink to={props.link} exact={props.exact}>
        <span
          className={cx([
            "text-black fluid-text-16px sm:text-[2.15vw] md:text-[1.25vw] lg:text-[18px] whitespace-nowrap",
          ])}
        >
          {props.link_title}
        </span>
      </NavLink>
    );
  }

  return <li className={cx(["w-full", "mb-[10px]"])}>{content}</li>;
};

export default LinkListItem;
