import React from "react";
import cx from "classnames";

import EmployeesListItem from "./EmployeesListItem.js";

const EmployeesList = (props) => {
  return (
    <React.Fragment>
      <div
        className={cx([
          "block sm:flex sm:justify-between sm:items-start",
          "pt-[30px]",
        ])}
      >
        <div
          className={cx([
            props.department ? "block" : "hidden",
            "w-full sm:w-[25%]",
            "mb-[20px] sm:mb-[0]",
            "pr-[0] sm:pr-[3%]",
            "font-600 leading-[1.5] text-[20px] sm:text-[22px] md:text-[24px]",
            props.department &&
              props.colors[props.department] &&
              `text-${props.colors[props.department].dark}`,
          ])}
        >
          {props.department && props.department}
        </div>
        <ul
          className={cx([
            "grid grid-cols-2",
            props.department
              ? "w-full sm:w-[calc(75%+(75%-160px*4)/4)] sm:mx-[calc(-1*((75%-160px*4)/4/2))] sm:grid-cols-3 md:grid-cols-4"
              : "w-full sm:w-[calc(100%+(100%-160px*4)/4)] sm:mx-[calc(-1*((100%-160px*4)/4/2))] sm:grid-cols-4",
          ])}
        >
          {props.employees.map((employee, i) => {
            return (
              <EmployeesListItem
                twoColumnsLayout={props.department}
                employee={employee}
                key={i}
                colors={props.colors}
                showEmployeeBioHandler={props.showEmployeeBioHandler}
              />
            );
          })}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default EmployeesList;
