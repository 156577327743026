import React from "react";
import cx from "classnames";

const Loader = () => {
  return (
    <div
      className={cx([
        "flex justify-center items-center",
        "fixed top-[0] bottom-[0] left-[0] right-[0]",
      ])}
    >
      <div
        className={cx("lds-spinner", "inline-block relative w-[80px] h-[80px]")}
      >
        {[...Array(12)].map((e, i) => (
          <div
            key={i}
            className={cx(
              "after:content-[''] after:block",
              "after:absolute after:top-[3px] after:left-[37px] after:w-[6px] after:h-[18px]",
              "after:rounded-[20%]",
              process.env.REACT_APP_IS_EOSSITE === "TRUE"
                ? "after:bg-[#5761b8]"
                : "after:bg-[#f1a06a]"
            )}
            style={{
              transform: `rotate(${i * 30}deg)`,
              transformOrigin: "40px 40px",
              animationName: "lds-spinner",
              animationDuration: "1.2s",
              animationIterationCount: "infinite",
              animationTimingFunction: "linear",
              animationDelay: `${-1 + i * 0.1}s`,
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Loader;
