import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import { Helmet } from "react-helmet";

import { useParams } from "react-router";
import Details from "./Details";
import Overview from "./Overview";
import Gallery from "./Gallery";
import RelatedProjects from "./RelatedProjects";
import Loader from "../Loader";

import _ from "lodash";
import { fetchProject, fetchRelatedProjects } from "../../http";
import Awards from "./Awards";

const Project = () => {
  const [loading, setLoading] = useState(true);
  const [, setError] = useState(false);
  const { id } = useParams();
  const [project, setProject] = useState({});
  const [relatedProjects, setRelatedProjects] = useState([]);
  const ref = useRef(null);

  /*
   * get projects data
   */
  useEffect(() => {
    setLoading(true);

    fetchProject(id)
      .then((res) => {
        setProject(res);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const removeSpecialChars = (str) => {
    return str
      .replace("&", "and")
      .replace(/(?!\w|\s)./g, "")
      .replace(/\s+/g, "")
      .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, "$2")
      .toLowerCase();
  };

  /*
   * get related projects
   */
  useEffect(() => {
    const array = _.get(project, ["servicesCategory"], []).map((s) => {
      //e.g. "Controls & Programming" becomes "controlsandprogramming"
      return removeSpecialChars(s.name);
    });

    const payload = {
      cats: array,
    };

    fetchRelatedProjects(payload, id).then((res) => {
      const projectsData = res.map((p, i) => {
        return {
          ...p,
          index: i,
        };
      });

      setRelatedProjects(projectsData);
    });
  }, [project, id]);

  let content;
  if (loading) {
    content = <Loader />;
  } else {
    content = (
      <>
        <div className={cx(["w-full"])}>
          <div
            className={cx([
              "relative",
              "pt-[50%]",
              "overflow-hidden",
              "bg-[#cccccc] bg-cover bg-center",
              "flex justify-center items-center",
            ])}
          >
            {project.hero ? (
              <img
                alt=""
                src={project.hero}
                className={cx([
                  "w-full",
                  "absolute top-[50%] left-0 translate-y-[-50%]",
                ])}
              />
            ) : (
              <div className="text-[#6b6b6b]">Couldn't load image</div>
            )}
          </div>
        </div>
        <div className={cx(["page-width"])}>
          <div
            className={cx([
              "pt-[50px] md:pt-[100px]",
              "pb-[25px] md:pb-[50px]",
              "block sm:flex sm:justify-between sm:items-start flex-wrap md:flex-nowrap",
            ])}
          >
            <Details project={project} />
          </div>
          <div
            className={cx([
              "ml-[auto] mr-[auto]",
              "max-w-[100%] sm:max-w-[80%] md:max-w-[750px]",
            ])}
          >
            {_.get(project, ["awards", 0], "") &&
            project.awards[0].length > 0 ? (
              <Awards awards={project.awards} />
            ) : (
              ""
            )}
            {project.overview ? <Overview overview={project.overview} /> : ""}
          </div>
        </div>

        {project.gallery.length > 0 ? (
          <Gallery
            gallery={project.gallery}
            photographer={project.photographer}
          />
        ) : (
          ""
        )}

        <div
          className={cx([
            "page-width",
            "mt-[90px] md:mt-[180px] mb-[25px] md:mb-[50px]",
          ])}
        >
          <RelatedProjects projects={relatedProjects} />
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          Work: {_.get(project, ["name"], "")} -&nbsp;
          {process.env.REACT_APP_IS_EOSSITE === "TRUE"
            ? "Eos Lightmedia"
            : "Abernathy Lighting Design"}
        </title>
      </Helmet>

      <div ref={ref} className={cx(["min-h-[100vh]", "mt-[60px] sm:mt-[0]"])}>
        {content}
      </div>
    </>
  );
};

export default Project;
