import React from "react";
import ScrollSnapSection from "../../components/ScrollSnapSection";
import PrivacyCopy from "../../components/Privacy/PrivacyCopy";
import { Helmet } from "react-helmet";

const Privacy = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Privacy -&nbsp;
          {process.env.REACT_APP_IS_EOSSITE === "TRUE"
            ? "Eos Lightmedia"
            : "Abernathy Lighting Design"}
        </title>
      </Helmet>

      <ScrollSnapSection showScrollButton={false} id="privacy">
        <PrivacyCopy />
      </ScrollSnapSection>
    </React.Fragment>
  );
};

export default Privacy;
