import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import _ from "lodash";

import Description from "./Description";
import AreasOfExpertise from "./AreasOfExpertise";
import FeaturedProjects from "./FeaturedProjects";

import { fetchServiceBySlug } from "../../http";
import { fetchFeaturedProjects } from "../../http";

import { useParams } from "react-router";
import { Helmet } from "react-helmet";

const Service = () => {
  const { slug } = useParams();
  const [service, setService] = useState({});
  const [featuredProjects, setRelatedProjects] = useState([]);
  const ref = useRef(null);

  /*
   * get featured projects data
   */
  useEffect(() => {
    fetchFeaturedProjects(slug).then((res) => {
      const projectsData = res.map((p, i) => {
        return {
          ...p,
          index: i,
        };
      });

      setRelatedProjects(projectsData);
    });
  }, [slug]);

  /*
   * get service data
   */
  useEffect(() => {
    ref.current && ref.current.classList.add("fade-out");
    fetchServiceBySlug(slug).then((res) => {
      setService(res);
      ref.current && ref.current.classList.remove("fade-out");
      ref.current && ref.current.classList.add("fade-in");
    });
  }, [slug]);

  return (
    <>
      <Helmet>
        <title>
          Services &#8211;
          {_.get(service, ["attributes", "title"], "")} &#8211; Eos Lightmedia
        </title>
      </Helmet>

      <div className="min-h-[100vh]">
        {service.id ? (
          <div ref={ref} className={cx(["content", "bg-white"])}>
            <div className={cx(["w-full"])}>
              <img
                src={_.get(
                  service,
                  ["attributes", "featured_image", "data", "attributes", "url"],
                  ""
                )}
                alt=""
                className={cx([
                  "w-full h-[30vh] md:h-[50vh] lg:h-[480px]",
                  "object-cover",
                ])}
              />
            </div>
            <div className={cx(["page-width"])}>
              <Description
                title={service.id && service.attributes.title}
                overview={service.id && service.attributes.overview}
              />

              <div
                className={cx([
                  "areas-of-expertise",
                  "pt-[40px] md:pt-[40px] pb-[40px] md:pb-[100px]",
                  "block md:flex md:justify-between md:items-start",
                  "border-b-[1px] border-[black] border-solid",
                ])}
              >
                <div
                  className={cx([
                    "w-full md:w-[50%]",
                    "pr-[0] md:pr-[10%] mb-[20px] md:mb-[0]",
                  ])}
                >
                  <h2
                    className={cx([
                      "leading-[1.17] text-black font-500 text-[22px] md:text-[28px]",
                    ])}
                  >
                    Areas of expertise
                  </h2>
                </div>

                <div
                  className={cx([
                    "w-full md:w-[25%]",
                    "md:pt-[15px] pr-[0] md:pr-[5%]",
                  ])}
                >
                  <AreasOfExpertise
                    areas={[
                      {
                        area: "DESIGN",
                        labels: _.get(
                          service,
                          ["attributes", "areas_of_expertise_design", "data"],
                          []
                        ).sort((a, b) =>
                          a.attributes.label.localeCompare(b.attributes.label)
                        ),
                      },
                    ]}
                  />
                </div>

                <div
                  className={cx([
                    "w-full md:w-[25%]",
                    "md:pt-[15px] pl-[0] md:pl-[5%]",
                  ])}
                >
                  <AreasOfExpertise
                    areas={[
                      {
                        area: "BUILD",
                        labels: _.get(
                          service,
                          ["attributes", "areas_of_expertise_build", "data"],
                          []
                        ).sort((a, b) =>
                          a.attributes.label.localeCompare(b.attributes.label)
                        ),
                      },

                      {
                        area: "POST BUILD SERVICES",
                        labels: _.get(
                          service,
                          [
                            "attributes",
                            "areas_of_expertise_post_build_services",
                            "data",
                          ],
                          []
                        ).sort((a, b) =>
                          a.attributes.label.localeCompare(b.attributes.label)
                        ),
                      },
                    ]}
                  />
                </div>
              </div>

              <FeaturedProjects projects={featuredProjects} />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Service;
