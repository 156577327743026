import React from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

const Button = (props) => {
  let button;
  if (props.link) {
    if (props.hashLink) {
      button = (
        <NavHashLink to={props.link} exact={props.exact}>
          {props.title}
        </NavHashLink>
      );
    } else if (props.anchor) {
      button = <a href={props.link}>{props.title}</a>;
    } else {
      button = (
        <NavLink to={props.link} exact={props.exact}>
          {props.title}
        </NavLink>
      );
    }
  } else {
    button = <button type={props.type}>{props.title}</button>;
  }

  return (
    <div
      className={cx([
        `btn-standard ${
          process.env.REACT_APP_IS_EOSSITE === "TRUE" ? "purple" : "orange"
        }`,
        {
          "btn-black": props.black,
          "btn-effect": props.effect,
          "btn-effect2": props.effect2,
          active: props.isActive,
        },
      ])}
    >
      {button}

      {(props.effect || props.effect2) && (
        <div
          className={cx(
            "inline-block content-['']",
            "absolute top-[-1px] bottom-[-1px] left-[-1px] right-[-1px]",
            "z-[0]",
            "rounded-[10px]"
          )}
          style={{
            background:
              process.env.REACT_APP_IS_EOSSITE === "TRUE"
                ? "linear-gradient(80deg, #1c3ba1 -1%, #5761b8 45%, #c639a5 98%)"
                : "linear-gradient(80deg, #e85127 -1%, #f1a06a 45%, #e9d46b 98%)",
          }}
        ></div>
      )}
    </div>
  );
};

export default Button;
