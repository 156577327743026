import React from "react";
import cx from "classnames";

const Field = (props) => {
  let detail;

  if (typeof props.detail === "string") {
    detail = (
      <span className={cx(["text-[18px] font-500 leading-[1]"])}>
        {props.detail}
      </span>
    );
  } else {
    detail = (
      <ul className={cx(["flex justify-start items-start flex-wrap"])}>
        {props.detail}
      </ul>
    );
  }

  return (
    <div className={cx(["w-[100%]"])}>
      <h3 className={cx(["mb-[5px] md:mb-[10px]", "text-[14px] uppercase"])}>
        {props.title}
      </h3>
      <div className={cx(["mb-[20px]"])}>{detail}</div>
    </div>
  );
};

export default Field;
