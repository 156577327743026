import React, { useState, useEffect } from "react";
import ScrollSnapSection from "../../components/ScrollSnapSection";
import Hero from "../../components/Hero";
import OurStory from "../../components/Company/OurStory";
import Overview from "../../components/Company/Overview";
import Awards from "../../components/Company/Awards";
import backgroundImage1 from "../../assets/images/gradient_bg_01.jpg";
import backgroundImage3 from "../../assets/images/gradient_bg_03.jpg";
import backgroundImageOrange from "../../assets/images/gradient_bg_orange.jpg";

import { fetchFieldByPage, fetchOffices } from "../../http";
import _ from "lodash";
import { Helmet } from "react-helmet";
import TeamContainer from "../../components/Team/TeamContainer";
import AboutEOS from "../../components/Company/AboutEOS";
import Offices from "../../components/Company/Offices";

const Company = (props) => {
  const [data, setData] = useState({});

  useEffect(() => {
    let companyData;
    fetchFieldByPage("company")
      .then((res) => {
        companyData = res;
        return fetchOffices();
      })
      .then((officesRes) => {
        if (companyData && officesRes) {
          setData({
            ...companyData,
            offices: {
              ...companyData.offices,
              office_information: officesRes.attributes.office_information,
            },
          });
        }
      });
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>
          About -&nbsp;
          {process.env.REACT_APP_IS_EOSSITE === "TRUE"
            ? "Eos Lightmedia"
            : "Abernathy Lighting Design"}
        </title>
      </Helmet>

      {process.env.REACT_APP_IS_EOSSITE === "TRUE" && (
        <ScrollSnapSection
          backgroundColor="#000"
          backgroundVideo={_.get(
            data,
            ["overview_video", "data", "attributes", "url"],
            ""
          )}
          video={_.get(
            data,
            ["overview_video", "data", "attributes", "url"],
            ""
          )}
          showScrollButton={false}
          showOverlay
          id="hero"
        >
          <Hero heading="" showOutlinedHeading={false} showVideoButton={true} />
        </ScrollSnapSection>
      )}

      <ScrollSnapSection
        backgroundImage={
          process.env.REACT_APP_IS_EOSSITE === "TRUE"
            ? backgroundImage3
            : backgroundImageOrange
        }
        showScrollButton={true}
        isCardStyle
        id="ourstory"
      >
        <OurStory data={_.get(data, ["our_story"], {})} />
      </ScrollSnapSection>

      {process.env.REACT_APP_IS_EOSSITE === "TRUE" && (
        <ScrollSnapSection
          backgroundImage={backgroundImage1}
          showScrollButton={true}
          isCardStyle
          id="overview"
        >
          <Overview
            data={_.get(data, ["company_snapshot"], {})}
            feature={_.get(data, ["feature"], [])}
          />
        </ScrollSnapSection>
      )}

      {process.env.REACT_APP_IS_EOSSITE === "TRUE" && (
        <ScrollSnapSection
          backgroundColor={"white"}
          showScrollButton={true}
          id="offices"
        >
          <Offices
            offices={
              data.offices &&
              data.offices.office_information &&
              _.get(data, ["offices"], {})
            }
          />
        </ScrollSnapSection>
      )}

      {process.env.REACT_APP_IS_EOSSITE !== "TRUE" && (
        <ScrollSnapSection showScrollButton={true} isCardStyle id="aboutEOS">
          <AboutEOS data={_.get(data, ["about_eos"], {})} />
        </ScrollSnapSection>
      )}

      <ScrollSnapSection showScrollButton={false} id="awards">
        <Awards data={_.get(data, ["awards"], "")} />
      </ScrollSnapSection>

      {process.env.REACT_APP_IS_EOSSITE === "FALSE" && <TeamContainer />}
    </React.Fragment>
  );
};

export default Company;
